<template>
  <div class="green-to-blue-page-gradient">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'PublicLayout'
}
</script>
