import { createBatch, createId, getFirestoreDoc, getFirestoreDocs, firebaseArrayRemove } from '@/firebase/firestore'
import { tracePerformance } from '@/firebase/performance'
import { logAnalyticsEvent } from '@/firebase/analytics'
import { getUid } from '@/firebase/auth'
import localizeFilter from '@/filters/localize.filter'
import useNotifications from '@/composables/useNotifications'
import router from '../router'

export default {
  state: {
    tags: {},
    chosenTagId: null,
    tagsReset: 0,
    tagsFilters: {
      section: null,
      searchStr: ''
    },
    tagsSort: {
      field: 'Tag',
      direction: 'asc'
    },
    showArchivedTags: false,
    tagsSums: {}
  },
  mutations: {
    resetTags(state) {
      state.tagsReset = Date.now()
    },
    addTag(state, { tagId, data }) {
      if (tagId && data) {
        state.tags[tagId] = data
      }
    },
    updateTag(state, { tagId, data }) {
      if (tagId && data) {
        if (!state.tags[tagId]) {
          state.tags[tagId] = {}
        }

        for (const key of Object.keys(data)) {
          state.tags[tagId][key] = data[key]
        }
      }
    },
    deleteTagFromStore(state, tagId) {
      if (tagId && state.tags[tagId]) {
        delete state.tags[tagId]
      }
    },
    setChosenTagId(state, tagId) {
      state.chosenTagId = tagId
    },
    setTagsFiltersSearchStr(state, value) {
      if (!value) { value = '' }
      state.tagsFilters.searchStr = value
    },
    setShowArchivedTags(state, value) {
      state.showArchivedTags = value
    },
    setTagsSortDirection(state, direction) {
      state.tagsSort.direction = direction
    },
    setTagsSortField(state, field) {
      state.tagsSort.field = field
    },
    setTagSum(state, { tagsSumsId, data }) {
      if (tagsSumsId && data) {
        state.tagsSums[tagsSumsId] = data
      }
    },
    updateTagSum(state, { tagsSumsId, month, difference }) {
      if (tagsSumsId && state.tagsSums[tagsSumsId] && month && state.tagsSums[tagsSumsId][month + ''] !== undefined && difference) {
        state.tagsSums[tagsSumsId][month + ''] = +state.tagsSums[tagsSumsId][month + ''] + +difference
      }
    },
    clearOneTagsSums(state, tagsSumsId) {
      if (tagsSumsId && state.tagsSums[tagsSumsId]) {
        delete state.tagsSums[tagsSumsId]
      }
    },
    clearTagsSumsForTagId(state, tagId) {
      if (tagId && state.tags[tagId] && state.tags[tagId].sums && Object.keys(state.tags[tagId].sums).length) {
        for (const tagsSumsId of Object.values(state.tags[tagId].sums)) {
          delete state.tagsSums[tagsSumsId]
        }
      }
    },
    setTagMinMaxYear(state, { tagId, year }) {
      if (tagId && year && state.tags[tagId]) {
        if (!state.tags[tagId].tagMinYear || state.tags[tagId].tagMinYear > year) {
          state.tags[tagId].tagMinYear = year
        }

        if (!state.tags[tagId].tagMaxYear || state.tags[tagId].tagMaxYear < year) {
          state.tags[tagId].tagMaxYear = year
        }
      }
    },
    clearTagMinAndMaxYears(state, tagId) {
      if (tagId && state.tags[tagId]) {
        if (state.tags[tagId].tagMinYear) {
          delete state.tags[tagId].tagMinYear
        }

        if (state.tags[tagId].tagMaxYear) {
          delete state.tags[tagId].tagMaxYear
        }
      }
    },
    clearTagsFilters(state) {
      state.tagsFilters = {
        section: null,
        searchStr: ''
      }
    },
    clearInfo(state) {
      state.tags = {}
      state.chosenTagId = null
      state.tagsFilters = {
        section: null,
        searchStr: ''
      }
      state.tagsSort = {
        field: 'Tag',
        direction: 'asc'
      }
      state.showArchivedTags = false
      state.tagsSums = {}
    }
  },
  actions: {
    async fetchTags({ commit, dispatch, getters }, active) {
      if (active === undefined) {
        dispatch('setError', localizeFilter('Error'))
        return false
      }

      const request = {
        wheres: [
          ['owner', '==', getUid()],
          ['active', '==', active]],
        order: [['timestamp', 'desc']]
      }

      const tarifLimit = getters.countFetchLimit('maxTags')
      if (tarifLimit === undefined || tarifLimit === 0) {
        return false
      } else if (tarifLimit && Number.isInteger(tarifLimit)) {
        request.maxLimit = tarifLimit
      }

      const t = tracePerformance('fetchTags')
      t.start()

      try {
        const tags = await getFirestoreDocs({
          collectionName: 'tags',
          ...request
        })

        if (tags) {
          for (const tag of tags) {
            await dispatch('tagAdded', {
              tagId: tag.id,
              data: tag.data,
              increaseTotalNumber: 0
            })
          }

          if (active === true) {
            await commit('setLoadedAll', { field: 'tagsActive', value: true })
          } else if (active === false) {
            await commit('setLoadedAll', { field: 'tagsArchived', value: true })
          }
        }

        return true
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'fetchTags', params: { active } })
        return false
      } finally {
        t.stop()
      }
    },
    async fetchTag({ dispatch }, tagId) {
      if (!tagId) {
        dispatch('setError', localizeFilter('Error'))
        return false
      }

      const t = tracePerformance('fetchTag')
      t.start()

      try {
        const data = await getFirestoreDoc('tags', tagId)

        if (data) {
          await dispatch('tagAdded', {
            tagId,
            data,
            increaseTotalNumber: 0
          })
        }
      } catch (e) {
        if (e.code !== 'permission-denied') {
          dispatch('saveErrorInfo', { error: e, location: 'fetchTag', params: { tagId } })
        }
      } finally {
        t.stop()
      }
    },
    async fetchTagSums({ commit, dispatch, getters }, tagsSumsIdsArr) {
      if (!tagsSumsIdsArr || !tagsSumsIdsArr.length) { return }
      if (!getters.getLimit('tagAnalytics')) { return }

      const t = tracePerformance('fetchTagSums')
      t.start()

      try {
        for (const tagsSumsId of tagsSumsIdsArr) {
          const data = await getFirestoreDoc('tagsSums', tagsSumsId)

          if (data) {
            await commit('setTagSum', { tagsSumsId, data })
          }
        }
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'fetchTagSums', params: { tagsSumsIdsArr } })
      } finally {
        t.stop()
      }
    },
    async fetchTagMinAndMaxYears({ commit, dispatch, getters }, tagId) {
      if (!tagId) {
        dispatch('setError', localizeFilter('Error'))
        return false
      }

      if (!getters.getLimit('tagAnalytics')) {
        return false
      }

      const t = tracePerformance('fetchTagMinAndMaxYears')
      t.start()

      try {
        const requestMin = {
          maxLimit: 1,
          wheres: [
            ['owner', '==', getUid()],
            ['tags', 'array-contains', tagId]
          ],
          order: [['date', 'asc']]
        }

        const transactionsMin = await getFirestoreDocs({
          collectionName: 'transactions',
          ...requestMin
        })

        if (transactionsMin) {
          for (const transaction of transactionsMin) {
            if (transaction.data.date) {
              await commit('setTagMinMaxYear', { tagId, year: new Date(+transaction.data.date).getFullYear() })
            }
          }
        }

        const requestMax = {
          maxLimit: 1,
          wheres: [
            ['owner', '==', getUid()],
            ['tags', 'array-contains', tagId]
          ],
          order: [['date', 'desc']]
        }

        const transactionsMax = await getFirestoreDocs({
          collectionName: 'transactions',
          ...requestMax
        })

        if (transactionsMax) {
          for (const transaction of transactionsMax) {
            if (transaction.data.date) {
              await commit('setTagMinMaxYear', { tagId, year: new Date(+transaction.data.date).getFullYear() })
            }
          }
        }
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'fetchTagMinAndMaxYears', params: { tagId: tagId } })
      } finally {
        t.stop()
      }
    },
    async checkIfTagAlreadyExists({ dispatch, getters }, tag) {
      if (!tag || !tag.name || !tag.nameValid) { return }

      const tagIds = Object.keys(getters.tags)
      if (tagIds.length) {
        const existingTags = tagIds.filter(tagId => { return getters.tags[tagId].name === tag.name })
        if (existingTags && existingTags.length) { return existingTags[0] }
      }

      const t = tracePerformance('checkIfTagAlreadyExists')
      t.start()

      const { toastify } = useNotifications()
      let toastId

      try {
        toastId = toastify.warning(localizeFilter('Checking') + '...', { timeout: null })

        const request = {
          maxLimit: 1,
          wheres: [
            ['owner', '==', getUid()],
            ['name', '==', tag.name]
          ]
        }

        const tags = await getFirestoreDocs({
          collectionName: 'tags',
          ...request
        })

        if (tags) {
          for (const tag of tags) {
            await dispatch('tagAdded', {
              tagId: tag.id,
              data: tag.data,
              increaseTotalNumber: 0
            })
          }

          await toastify.remove(toastId)
          toastify.replace(toastId, localizeFilter('TagAlreadyExists'), 'error')
          return tags[0].id
        }

        toastify.remove(toastId)
        return false
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'checkIfTagAlreadyExists', params: { tagName: tag.name }, toastId })
      } finally {
        t.stop()
      }
    },
    addTagButtonClicked({ commit, getters }, { from, tagId = null }) {
      if (!tagId && !getters.getAvailableLimitNumber('tagsReset', 'tags', 'maxTags')) {
        commit('showPopUp', { name: 'PayWall' })
        return
      }

      commit('showPopUp', {
        name: 'EditTag',
        incomingData: { tagId }
      })

      if (from) { logAnalyticsEvent('addTagClicked', { from, tagId }) }
    },
    async createTag({ commit, dispatch, getters }, tag) {
      if (!getters.online) { return }

      const { toastify } = useNotifications()

      if (getters.processing) {
        toastify.error(localizeFilter('WaitPreviousTask'), {
          icon: 'hourglass_empty'
        })
        return
      }

      if (!getters.getLimitNumber('maxTags') || !getters.getAvailableLimitNumber('tagsReset', 'tags', 'maxTags') || +getters.userStats('tags') >= getters.getLimitNumber('maxTags')) {
        commit('showPopUp', { name: 'PayWall' })
        return
      }

      if (!tag || !tag.tagValid) {
        dispatch('setError', localizeFilter('Error'))
        return
      }

      const existingTagId = await dispatch('checkIfTagAlreadyExists', tag)
      if (existingTagId === undefined) { return }
      if (existingTagId) {
        tag.existingTagId = existingTagId
        return
      }

      const t = tracePerformance('createTag')
      t.start()

      const syncTimestamp = new Date()

      tag.timestamp = syncTimestamp

      let toastId

      try {
        commit('setProcessing', true)
        toastId = toastify.warning(localizeFilter('CreatingTag') + '...', { timeout: null })

        const tagId = createId('tags')

        const batchArray = await createBatch([
          {
            timestamp: syncTimestamp,
            type: 'set',
            place: 'tags',
            id: tagId,
            data: tag.newTagData(),
            updateStats: 1
          }
        ])

        await dispatch('subscribeToLogs', syncTimestamp)

        for (const batch of batchArray) { await batch.commit() }

        await dispatch('tagAdded', {
          tagId,
          data: tag.newTagData(),
          increaseTotalNumber: 1
        })

        toastify.replace(toastId, localizeFilter('TagCreated'), 'success')
        return tagId
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'createTag', params: { data: tag.newTagData() }, toastId })
        return false
      } finally {
        commit('setProcessing', false)
        t.stop()
      }
    },
    async tagAdded({ commit, dispatch }, { tagId, data, increaseTotalNumber = 0 }) {
      if (!tagId || !data) {
        dispatch('setError', localizeFilter('Error'))
        return false
      }

      await commit('addTag', { tagId, data })

      if (increaseTotalNumber) {
        await commit('increaseTotalNumberOf', { field: 'tags', number: increaseTotalNumber })
      }

      await commit('resetTags')
    },
    async editTag({ commit, dispatch, getters }, tag) {
      if (!getters.online) { return }

      const { toastify } = useNotifications()

      if (getters.processing) {
        toastify.error(localizeFilter('WaitPreviousTask'), {
          icon: 'hourglass_empty'
        })
        return
      }

      if (!getters.getLimitNumber('maxTags') || (+getters.userStats('tags') > getters.getLimitNumber('maxTags'))) {
        commit('showPopUp', { name: 'PayWall' })
        return false
      }

      const editedTagData = tag.editedTagData()

      if (!tag || !tag.tagChanged || !editedTagData || !tag.tagId) {
        dispatch('setError', localizeFilter('Error'))
        return
      }

      if (editedTagData.active && getters.userStats('tags') >= getters.getLimitNumber('maxTags')) {
        commit('showPopUp', { name: 'PayWall' })
        return false
      }

      if (tag.nameChanded) {
        const existingTagId = await dispatch('checkIfTagAlreadyExists', tag)
        if (existingTagId === undefined) { return }
        if (existingTagId) {
          tag.existingTagId = existingTagId
          return
        }
      }

      const t = tracePerformance('editTag')
      t.start()

      const syncTimestamp = new Date()

      let toastId

      try {
        commit('setProcessing', true)
        toastId = toastify.warning(localizeFilter('Saving') + '...', { timeout: null })

        if (Object.keys(editedTagData).length === 1 && editedTagData.active !== undefined) {
          logAnalyticsEvent('archieveTagClicked', { active: editedTagData.active })
        }

        const batchData = [
          {
            timestamp: syncTimestamp,
            type: 'update',
            place: 'tags',
            id: tag.tagId,
            data: tag.editedTagData(true),
            logDataInfo: editedTagData
          }
        ]

        if (editedTagData.active === false) {
          const request = {
            wheres: [
              ['owner', '==', getUid()],
              ['tags', 'array-contains', tag.tagId]
            ]
          }

          const tagsCollections = await getFirestoreDocs({
            collectionName: 'tagsCollections',
            ...request
          })

          if (tagsCollections) {
            for (const collect of tagsCollections) {
              batchData.push({
                timestamp: syncTimestamp,
                type: 'update',
                place: 'tagsCollections',
                id: collect.id,
                data: {
                  tags: firebaseArrayRemove(tag.tagId)
                }
              })
            }
          }
        }

        const batchArray = await createBatch(batchData)

        await dispatch('subscribeToLogs', syncTimestamp)

        for (const batch of batchArray) { await batch.commit() }

        await dispatch('tagEdited', { tagId: tag.tagId, data: editedTagData, deleteFromAllCollections: editedTagData.active === false ? true : false })
        toastify.replace(toastId, localizeFilter('Saved'), 'success')

        return true
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'editTag', params: { tag: JSON.stringify(tag) }, toastId })
        return false
      } finally {
        commit('setProcessing', false)
        t.stop()
      }
    },
    async tagEdited({ commit, dispatch, getters }, { tagId, data, deleteFromAllCollections }) {
      if (!tagId || !data) {
        dispatch('setError', localizeFilter('Error'))
        return false
      }

      const tag = getters.tags[tagId]

      if (deleteFromAllCollections) {
        await commit('deleteTagFromAllCollections', tagId)
        commit('resetTagsCollections')
      }

      if (tag) { await commit('updateTag', { tagId, data }) }

      commit('resetTags')
    },
    async deleteTag({ commit, dispatch, getters }, { tagId }) {
      if (!getters.online) { return }

      if (!tagId || !getters.tags[tagId]) {
        dispatch('setError', localizeFilter('Error'))
        return false
      }

      const { toastify } = useNotifications()

      if (getters.processing) {
        toastify.error(localizeFilter('Wait') + '...')
        return
      }

      const t = tracePerformance('deleteTag')
      t.start()

      const syncTimestamp = new Date()
      let toastId

      try {
        commit('setProcessing', true)
        toastId = toastify.warning(localizeFilter('DeletingTag') + '...', { timeout: null })

        const batchData = [
          {
            timestamp: syncTimestamp,
            type: 'delete',
            place: 'tags',
            id: tagId,
            updateStats: -1
          }
        ]

        if (Object.keys(getters.tags[tagId].sums).length) {
          for (const tagsSumsId of Object.values(getters.tags[tagId].sums)) {
            batchData.push(
              {
                timestamp: syncTimestamp,
                type: 'delete',
                place: 'tagsSums',
                id: tagsSumsId,
                noLogs: true
              }
            )
          }
        }

        const request = {
          wheres: [
            ['owner', '==', getUid()],
            ['tags', 'array-contains', tagId]
          ]
        }

        const tagsCollections = await getFirestoreDocs({
          collectionName: 'tagsCollections',
          ...request
        })

        if (tagsCollections) {
          for (const tagsCollection of tagsCollections) {
            batchData.push(
              {
                timestamp: syncTimestamp,
                type: 'update',
                place: 'tagsCollections',
                id: tagsCollection.id,
                data: {
                  tags: firebaseArrayRemove(tagId)
                },
                noLogs: true
              }
            )
          }
        }

        const batchArray = await createBatch(batchData)

        await dispatch('subscribeToLogs', syncTimestamp)

        for (const batch of batchArray) { await batch.commit() }

        await dispatch('tagDeleted', tagId)
        toastify.replace(toastId, localizeFilter('TagDeleted'), 'success')
        logAnalyticsEvent('tagDeleted')
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'deleteTag', params: { tagId }, toastId })
        return false
      } finally {
        commit('setProcessing', false)
        t.stop()
      }
    },
    async tagDeleted({ commit, dispatch, getters }, tagId) {
      if (!tagId) {
        dispatch('setError', localizeFilter('Error'))
        return false
      }

      const tag = getters.tags[tagId]

      await commit('deleteTagFromAllCollections', tagId)
      commit('resetTagsCollections')

      await commit('clearTagMinAndMaxYears', tagId)
      await commit('clearTagsSumsForTagId', tagId)

      if (tag) {
        await commit('deleteTagFromStore', tagId)
      }

      await commit('increaseTotalNumberOf', { field: 'tags', number: -1 })

      await commit('resetTags')
      await commit('resetTransactions')
    },
    async removeNonExistentTagData({ dispatch, getters }, tagId) {
      if (!getters.online) { return }

      if (!tagId) {
        dispatch('setError', localizeFilter('Error'))
        return false
      }

      const t = tracePerformance('removeNonExistentTagData')
      t.start()

      const syncTimestamp = new Date()

      try {
        const batchData = [
          {
            timestamp: syncTimestamp,
            type: 'set',
            place: 'logs',
            logPlace: 'tags',
            logAction: 'deleted',
            id: tagId,
            data: {},
            updateStats: -1
          }
        ]

        const tagsSumsRequest = {
          wheres: [
            ['owner', '==', getUid()],
            ['tagId', '==', tagId]
          ]
        }

        const tagsSums = await getFirestoreDocs({
          collectionName: 'tagsSums',
          ...tagsSumsRequest
        })

        if (tagsSums) {
          for (const oneTagsSums of tagsSums) {
            batchData.push({
              timestamp: syncTimestamp,
              type: 'delete',
              place: 'tagsSums',
              id: oneTagsSums.id,
              noLogs: true
            })
          }
        }

        const tagsCollectionsRequest = {
          wheres: [
            ['owner', '==', getUid()],
            ['tags', 'array-contains', tagId]
          ]
        }

        const tagsCollections = await getFirestoreDocs({
          collectionName: 'tagsCollections',
          ...tagsCollectionsRequest
        })

        if (tagsCollections) {
          for (const tagsCollection of tagsCollections) {
            batchData.push({
              timestamp: syncTimestamp,
              type: 'update',
              place: 'tagsCollections',
              id: tagsCollection.id,
              data: {
                tags: firebaseArrayRemove(tagId)
              },
              noLogs: true
            })
          }
        }

        const batchArray = await createBatch(batchData)

        await dispatch('subscribeToLogs', syncTimestamp)

        for (const batch of batchArray) { await batch.commit() }

        await dispatch('tagDeleted', tagId)
        logAnalyticsEvent('nonExistentTagDataDeleted')
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'removeNonExistentTagData', params: { tagId } })
      } finally {
        t.stop()
      }
    },
    async openTagTransactions({ commit, getters }, tagId) {
      if (!tagId || !getters.tags[tagId] || !getters.tags[tagId].active) {
        const { toastify } = useNotifications()
        toastify.error(localizeFilter('Error'))
        return
      }

      if (!getters.getLimit('setTransactionsSettings')) {
        commit('showPopUp', { name: 'PayWall' })
        return
      }

      commit('setTransactionsSettings', {
        dateStart: null,
        dateEnd: null,
        sumStart: null,
        sumEnd: null,
        accountId: null,
        tags: [tagId]
      })
      commit('setLastFetchedTransaction', null)
      commit('clearTransactions')
      commit('setLoadedAll', { field: 'transactions', value: false })

      router.push({ name: 'Transactions' }).catch(() => { })
      logAnalyticsEvent('openTagTransactionsClicked')
    },
    async showArchivedTagsClicked({ dispatch, commit, getters }) {
      if (!getters.showArchivedTags) {
        if (!getters.loadedAll('tagsArchived')) {
          const { toastify } = useNotifications()
          const toastId = toastify.warning(localizeFilter('LoadingArchivedTags'), { timeout: null })

          const answer = await dispatch('fetchTags', false)
          if (answer) {
            toastify.replace(toastId, localizeFilter('ArchivedTagsLoaded'), 'info')
          } else {
            toastify.remove(toastId)
          }
        }
      }

      commit('setShowArchivedTags', !getters.showArchivedTags)
      logAnalyticsEvent('showArchivedTagsClicked')
    },
    clearTagsFiltersClicked({ commit }, from) {
      commit('clearTagsFilters')
      if (from) { logAnalyticsEvent('clearTagsFiltersClicked', { from }) }
    }
  },
  getters: {
    tags: s => s.tags,
    tagsReset: s => s.tagsReset,
    chosenTagId: s => s.chosenTagId,
    filteredTags: (s, getters) => {
      if (getters.tagsReset) {
        //
      }

      let tagIds = Object.keys(s.tags)

      if (!s.showArchivedTags) {
        tagIds = tagIds.filter(tagId => {
          return (s.tags[tagId] && s.tags[tagId].active)
        })
      }

      const limit = getters.getLimitNumber('maxTags')
      if (tagIds.length > limit) { tagIds.length = limit }

      tagIds = tagIds.filter(tagId => {
        return (
          (!s.tagsFilters.searchStr ||
            (
              s.tags[tagId] &&
              (
                (s.tags[tagId].name && s.tags[tagId].name.toLowerCase().includes(s.tagsFilters.searchStr.toLowerCase()))
                || (s.tags[tagId].comment && s.tags[tagId].comment.toLowerCase().includes(s.tagsFilters.searchStr.toLowerCase()))
                || (s.tags[tagId].icon && s.tags[tagId].icon.toLowerCase().includes(s.tagsFilters.searchStr.toLowerCase()))
              )
            )
          )
        )
      })

      if (tagIds.length > 1) {
        const sortParameter = s.tagsSort.field
        const sortType = s.tagsSort.direction

        tagIds.sort((a, b) => {
          const nameA = s.tags[a].name ? s.tags[a].name.toLowerCase() : ''
          const nameB = s.tags[b].name ? s.tags[b].name.toLowerCase() : ''

          if (sortParameter === 'Tag' || !sortParameter) {
            if (nameA === nameB) {
              if (a < b) { return -1 }
              if (a > b) { return 1 }
              return 0
            } else {
              return nameA.localeCompare(nameB)
            }
          } else {
            return nameA.localeCompare(nameB)
          }
        })

        if (sortType === 'desc') { tagIds.reverse() }
      }

      return tagIds
    },
    tagsFilters: s => s.tagsFilters,
    tagsFiltered: s => {
      if (s.tagsFilters.searchStr) { return true }
      return false
    },
    tagsSort: s => s.tagsSort,
    showArchivedTags: s => s.showArchivedTags,
    tagsSums: s => s.tagsSums
  }
}