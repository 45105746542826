<template>
  <div class="px-2_0 py-2_0 flex_column gap-1_0 fs-large color-grey">
    <div>
      <p v-if="!online">
        {{ $filters.localizeFilter('InternetAccessRequired') }}
      </p>

      <p v-if="online">
        {{ $filters.localizeFilter('InternetAccessRestored') }}.
      </p>

      <p v-if="online">
        {{ $filters.localizeFilter('ReloadPage') }}.
      </p>
    </div>

    <div class="flex_wrap gap-1_0">
      <button
        v-if="userId"
        v-tooltip="{
          text: $filters.localizeFilter('LogoutTheApp'),
          padding: 1
        }"
        class="modal-button overflow-dots fw-medium main-button-active"
        @click.prevent.stop="logout()"
      >
        {{ $filters.localizeFilter('Logout') }}
      </button>

      <button
        v-if="online"
        class="modal-button overflow-dots fw-medium main-button-active"
        @click.prevent.stop="reload()"
      >
        {{ $filters.localizeFilter('PageReload') }}
      </button>
    </div>
  </div>
</template>

<script>
import { getUid } from '@/firebase/auth'

export default {
  name: 'OfflinePage',
  data: () => ({
    userId: null
  }),
  computed: {
    online() {
      return this.$store.getters.online
    }
  },
  created () {
    if (this.online) {
      this.$router.push({ name: 'Home' }).catch(() => { })
    }

    this.userId = getUid()
  },
  methods: {
    reload() {
      document.location.reload()
    },
    logout () {
      this.$store.dispatch('logoutAndGoToLogin', { from: 'logout-button-offline-page' })
    }
  }
}
</script>