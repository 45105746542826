import { createBatch, getFirestoreDocs, onCollectionSnapshotHandler, firebaseTimestampFromDate } from '@/firebase/firestore'
import { getUid, getUserRigestred } from '@/firebase/auth'
import { tracePerformance } from '@/firebase/performance'
import localizeFilter from '@/filters/localize.filter'
import { prepareDateFields } from '@/utils/prepareDateFields'
import { logAnalyticsEvent } from '@/firebase/analytics'
import useNotifications from '@/composables/useNotifications'

export default {
  state: {
    news: {},
    newsReset: 0,
    lastFetchedNews: null,
    newsUnsubscribe: null
  },
  mutations: {
    setNews(state, { newsId, data }) {
      if (newsId && data) {
        state.news[newsId] = data
      }
    },
    deleteNewsFromStore(state, newsId) {
      if (newsId && state.news[newsId]) {
        delete state.news[newsId]
      }
    },
    resetNews(state) {
      state.newsReset = Date.now()
    },
    setLastFetchedNews(state, news) {
      state.lastFetchedNews = news
    },
    setNewsUnsubscribe(state, value) {
      state.newsUnsubscribe = value
    },
    clearInfo(state) {
      state.news = {}
      state.lastFetchedNews = null
      state.newsUnsubscribe = null
    }
  },
  actions: {
    showFrecsNews(context, newsLength) {
      if (!newsLength) { return }
      const { toastify } = useNotifications()
      toastify.info(localizeFilter('FreshNews') + ' (' + newsLength + ')', { timeout: 5000 })
    },
    async subscribeToNews({ commit, dispatch, getters }) {
      const newsRead = getters.userStats('newsRead')
      const locale = getters.locale
      const country = getters.country

      if (!newsRead || !locale || !country) {
        return
      }

      const t = tracePerformance('subscribeToNews')
      t.start()

      await dispatch('unsubscribeNews')

      try {
        const userRigestred = await getUserRigestred()

        const unsubscribe = onCollectionSnapshotHandler({
          collectionName: 'news',
          wheres: [['user', 'in', ['language:' + locale, 'country:' + country, getUid()]], ['timestamp', '>', newsRead], ['timestamp', '>=', firebaseTimestampFromDate(userRigestred)]],
          order: [['timestamp', 'desc']],
          successHandler: async querySnapshot => {
            querySnapshot.docChanges().forEach(async change => {
              if (change.type === 'added') {
                await commit('setNews', { newsId: change.doc.id, data: prepareDateFields(change.doc.data()) })
                commit('resetNews')
              }
            })
          },
          errorHandler: (error) => {
            dispatch('setError', error)
            dispatch('logoutAndGoToLogin', { from: 'news-subscription-error-handler' })
            return false
          }
        })

        await commit('setNewsUnsubscribe', unsubscribe)
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'subscribeToNews', params: {} })
        return false
      } finally {
        t.stop()
      }
    },
    async unsubscribeNews({ dispatch, getters }) {
      const t = tracePerformance('unsubscribeNews')
      t.start()

      try {
        const newsUnsubscribe = getters.newsUnsubscribe
        if (newsUnsubscribe) {
          await newsUnsubscribe()
        }
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'unsubscribeNews', params: {} })
        return false
      } finally {
        t.stop()
      }
    },
    async fetchArchivedNews({ commit, dispatch, getters }, { byClick = false }) {
      const locale = getters.locale
      const country = getters.country

      if (getters.loadedAll('news') || !locale) { return }

      const t = tracePerformance('fetchArchivedNews')
      t.start()

      const maxLimit = 5

      try {
        const userRigestred = await getUserRigestred()

        const request = {
          maxLimit,
          wheres: [['user', 'in', ['language:' + locale, 'country:' + country, getUid()]], ['timestamp', '>=', firebaseTimestampFromDate(userRigestred)]],
          order: [['timestamp', 'desc']]
        }

        const lastFetchedNews = await getters.lastFetchedNews
        if (lastFetchedNews) {
          request.start = lastFetchedNews
        }

        const news = await getFirestoreDocs({
          collectionName: 'news',
          ...request,
          needs: ['rawDoc']
        })

        if (news) {
          for (const nw of news) {
            await commit('setNews', { newsId: nw.id, data: nw.data })
            commit('resetNews')
          }

          await commit('setLastFetchedNews', news[news.length - 1].rawDoc)

          if (news.length < maxLimit) {
            await commit('setLoadedAll', { field: 'news', value: true })
          }
        } else {
          await commit('setLoadedAll', { field: 'news', value: true })
        }

        if (byClick) {
          logAnalyticsEvent('fetchArchivedNewsClicked')
        }
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'fetchArchivedNews', params: {} })
        return false
      } finally {
        t.stop()
      }
    },
    async updateNewsRead({ commit, dispatch, getters }) {
      if (!getters.online) { return }

      const t = tracePerformance('updateNewsRead')
      t.start()

      const syncTimestamp = new Date()
      const updatedDate = new Date(Date.now() + 3000)

      try {
        await commit('setProcessing', true)

        const batchArray = await createBatch([
          {
            timestamp: syncTimestamp,
            type: 'update',
            place: 'userStats',
            id: getUid(),
            data: {
              newsRead: updatedDate
            },
            noLogs: true
          },
          {
            timestamp: syncTimestamp,
            type: 'set',
            place: 'logs',
            logPlace: 'userStats',
            logAction: 'newsRead',
            id: +updatedDate,
            data: {}
          }
        ])

        await dispatch('subscribeToLogs', syncTimestamp)

        for (const batch of batchArray) { await batch.commit() }

        await dispatch('newsReadReceived', updatedDate)
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'updateNewsRead', params: {} })
      } finally {
        commit('setProcessing', false)
        t.stop()
      }
    },
    async newsReadReceived({ commit, dispatch }, date) {
      if (!date) {
        dispatch('setError', localizeFilter('Error'))
        return false
      }

      await commit('setNewsRead', date)
      commit('resetNews')
      await dispatch('subscribeToNews')
    }
  },
  getters: {
    news: s => s.news,
    newsArr: (s, getters) => unreadOnly => {
      if (getters.newsReset) {
        //
      }

      let answer = []

      const allNews = Object.keys(getters.news)

      if (allNews && allNews.length) {
        answer = allNews
      }

      if (unreadOnly) {
        answer = answer.filter(newsId => {
          const oneNews = s.news[newsId]
          return +oneNews.timestamp > +getters.userStats('newsRead')
        })
      }

      if (answer.length > 1) {
        answer.sort((a, b) => {
          const newsA = s.news[a].timestamp ? s.news[a].timestamp : new Date()
          const newsB = s.news[b].timestamp ? s.news[b].timestamp : new Date()

          if (newsA > newsB) { return -1 }
          if (newsA < newsB) { return 1 }
          return 0
        })
      }

      return answer
    },
    newsReset: s => s.newsReset,
    lastFetchedNews: s => s.lastFetchedNews,
    newsUnsubscribe: s => s.newsUnsubscribe
  }
}