<template>
  <div
    id="app"
    :class="{'dark': useDarkMode}"
  >
    <PopUp v-if="popUps.length" :key="popUps[0].id" />

    <Loader
      v-if="loadingPage"
      :sizepx="30"
      class="page-loader"
    />

    <component
      :is="layout"
      class="app-page"
    />
  </div>

  <AppNotifications />
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { mapGetters } from 'vuex'
import useNotifications from '@/composables/useNotifications'
import AppNotifications from '@/components/UI/AppNotifications'
import PublicLayout from '@/layouts/PublicLayout'
import PrivateLayout from '@/layouts/PrivateLayout'
import { setPageMetaTags } from '@/helpers/page-meta'
import { getUid } from '@/firebase/auth'

export default {
  setup () {
    const { toastify } = useNotifications()
    return { toastify }
  },
  components: {
    AppNotifications, PublicLayout, PrivateLayout,
    Loader: defineAsyncComponent(() => import('@/components/UI/loaders/Loader')),
    PopUp: defineAsyncComponent(() => import('@/components/app/PopUps/PopUp'))
  },
  data: () => ({
    onlineToast: null,
    offlineToast: null,
    matchMedia: null,
    useDarkMode: false
  }),
  computed: {
    ...mapGetters([
      'darkMode',
      'getAvailableLimitNumber',
      'loadingPage',
      'online',
      'popUps',
      'scrollAllowed'
    ]),
    layout () {
      return (this.$route.meta.layout || 'public') + '-layout'
    },
    addTransactionAllowed () {
      return this.$route.meta.addTransactionAllowed
    }
  },
  watch: {
    online (value) {
      if (value) {
        this.onlineToast = this.toastify.replace(this.offlineToast, this.$filters.localizeFilter('IsBackOnline'), 'success', {
          icon: 'wifi'
        })
      } else {
        this.offlineToast = this.toastify.replace(this.onlineToast, this.$filters.localizeFilter('NoInternetConnection'), 'error', {
          icon: 'wifi_off',
          timeout: null,
          eternal: true
        })
      }
    },
    darkMode () {
      this.setMatchMedia()
      this.handleDarkModeStatus(this.matchMedia)
    },
    scrollAllowed (value) {
      if (value) {
        document.body.style.overflow = ''
      } else {
        document.body.style.overflow = 'hidden'
      }
    }
  },
  created() {
    if (navigator.language) {
      this.$store.dispatch('newAppLanguage', { locale: navigator.language })
    }
    setPageMetaTags()
  },
  mounted() {
    window.addEventListener('online', this.handleOnlineStatus)
    window.addEventListener('offline', this.handleOnlineStatus)
    document.addEventListener('keydown', this.clickedButton)
    window.addEventListener('resize', this.handleResize)
    document.addEventListener('scroll', this.scrollHandler)

    this.setMatchMedia()

    if (this.matchMedia) {
      this.matchMedia.addEventListener('change', this.handleDarkModeStatus)
      this.handleDarkModeStatus(this.matchMedia)
    }
  },
  async beforeUnmount() {
    window.removeEventListener('online', this.handleOnlineStatus)
    window.removeEventListener('offline', this.handleOnlineStatus)
    document.removeEventListener('keydown', this.clickedButton)
    window.removeEventListener('resize', this.handleResize)
    document.removeEventListener('scroll', this.scrollHandler)

    if (this.matchMedia) { this.matchMedia.removeEventListener('change', this.handleDarkModeStatus) }
  },
  methods: {
    handleOnlineStatus () {
      this.$store.commit('setOnline', navigator.onLine)
    },
    removeAllNotKeepAliveTooltips () {
      const tooltips = document.getElementsByClassName('tooltip')
      for (const tooltip of tooltips) {
        if (tooltip.remove) { tooltip.remove() }
      }
    },
    handleResize () {
      this.removeAllNotKeepAliveTooltips()

      if (this.$store.getters.showResize) {
        this.$store.commit('setShowResize', false)
        setTimeout(() => {
          this.$store.commit('setShowResize', true)
        }, 300)
      }
    },
    scrollHandler () {
      this.removeAllNotKeepAliveTooltips()
    },
    setMatchMedia () {
      this.matchMedia = window.matchMedia('(prefers-color-scheme: dark)')
    },
    handleDarkModeStatus (event) {
      if (!event) { return }

      if (this.darkMode === 'dark') {
        if (!this.useDarkMode) { this.useDarkMode = true }
      } else if (this.darkMode === 'light') {
        if (this.useDarkMode) { this.useDarkMode = false }
      } else {
        if (event.matches && !this.useDarkMode) {
          this.useDarkMode = true
        } else if (!event.matches && this.useDarkMode) {
          this.useDarkMode = false
        }
      }
    },
    clickedButton(event) {
      if (!event) { return }

      if (event.code === 'Escape') {
        if (this.popUps.length) {
          this.$store.commit('closePopUp', { })
        } else {
          this.$store.dispatch('setCloseAllMenus')
        }
        this.$store.commit('setClickedButton', event)
        return
      }

      if (this.scrollAllowed && getUid()) {
        if (event.code === 'KeyK' && event.ctrlKey) {
          event.preventDefault()

          if (!this.getAvailableLimitNumber('transactionsReset', 'transactions', 'maxTransactions')) {
            this.$store.commit('showPopUp', { name: 'PayWall' })
            return
          }

          if (!this.addTransactionAllowed) { return }
          this.$store.dispatch('setCloseAllMenus')
          setTimeout(() => {
            this.$store.commit('showPopUp', { name: 'AddTransaction' })
          }, 0)
          return
        }

        if (event.code === 'KeyA' && event.ctrlKey) {
          event.preventDefault()
          this.$store.commit('setClickedButton', event)
          return
        }

        if (event.code === 'KeyB' && event.ctrlKey) {
          event.preventDefault()
          this.$store.commit('setClickedButton', event)
          return
        }

        if (event.code === 'KeyC' && event.ctrlKey && event.shiftKey) {
          event.preventDefault()
          this.$store.commit('setClickedButton', event)
          return
        }

        if (event.code === 'Backspace' && event.ctrlKey) {
          event.preventDefault()
          this.$store.commit('setClickedButton', event)
          return
        }

        if (event.code === 'Delete' && event.ctrlKey) {
          event.preventDefault()
          this.$store.commit('setClickedButton', event)
          return
        }

        if (['ArrowDown', 'ArrowUp', 'ArrowRight', 'ArrowLeft',].indexOf(event.code) >= 0) {
          this.$store.commit('setClickedButton', event)
          return
        }
      }
    }
  }
}
</script>