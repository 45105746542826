import { getValue, fetchAndActivate } from 'firebase/remote-config'
import { remoteConfig } from './firebase'
import store from '@/store'

let remoteConfigFetchTimeout = 1000

export let remoteConfig_country = null
export let remoteConfig_platform = null

export async function initRemoteConfig() {
  try {
    const rc = await remoteConfig()

    if (process.env.NODE_ENV !== 'production') {
      rc.settings.minimumFetchIntervalMillis = 10000
    }

    if (rc) {
      rc.defaultConfig = {
        'country': null,
        'platform': null
      }

      await fetchAndActivate(rc)

      remoteConfig_country = getValue(rc, 'country').asString()
      remoteConfig_platform = getValue(rc, 'platform').asString()

      store.commit('setRemoteConfigData', {
        remoteConfig_country: remoteConfig_country,
        remoteConfig_platform: remoteConfig_platform
      })
    }
  } catch (error) {
    if (error.code === 'remoteconfig/fetch-throttle') {
      setTimeout(() => {
        initRemoteConfig()
        remoteConfigFetchTimeout = remoteConfigFetchTimeout * 2
      }, remoteConfigFetchTimeout)
      return
    }

    store.dispatch('saveErrorInfo', { error, location: 'initRemoteConfig', params: {} })
  }
}