import { createBatch, createId, getFirestoreDocs, firestoreIncrement } from '@/firebase/firestore'
import { tracePerformance } from '@/firebase/performance'
import { logAnalyticsEvent } from '@/firebase/analytics'
import { getUid } from '@/firebase/auth'
import useNotifications from '@/composables/useNotifications'
import localizeFilter from '@/filters/localize.filter'
import router from '../router'

export default {
  state: {
    spreads: null,
    spreadsReset: 0,
    chosenSpreadId: null,
    canSaveSpread: false,
    spreadsDate: null,
    spreadLines: [],
    spreadsSum: null
  },
  mutations: {
    setSpreadsSum(state, value) {
      state.spreadsSum = value
    },
    setSpread(state, { spreadId, data }) {
      if (!state.spreads) { state.spreads = {} }

      if (spreadId && data) {
        state.spreads[spreadId] = data
      }
    },
    setSpreadLines(state, data) {
      state.spreadLines = data
    },
    deleteSpreadFromStore(state, spreadId) {
      if (state.spreads && state.spreads[spreadId]) {
        delete state.spreads[spreadId]
      }
    },
    resetSpreads(state) {
      state.spreadsReset = Date.now()
    },
    setChosenSpreadId(state, spreadId) {
      state.chosenSpreadId = spreadId
    },
    setCanSaveSpread(state, value) {
      state.canSaveSpread = value
    },
    setSpreadsDate(state, value) {
      state.spreadsDate = new Date(+value)
    },
    clearSpreads(state) {
      state.spreads = null
      state.spreadsReset = 0
    },
    clearSpreadLines(state) {
      state.spreadLines = []
    },
    clearInfo(state) {
      state.spreads = null
      state.chosenSpreadId = null
      state.spreadsDate = null
      state.spreadLines = []
    }
  },
  actions: {
    async fetchSpreads({ commit, dispatch, getters }) {

      if (!getters.getLimit('spreads')) { return }

      const t = tracePerformance('fetchSpreads')
      t.start()

      try {
        const request = { wheres: [['owner', '==', getUid()]], maxLimit: 5 }

        const spreads = await getFirestoreDocs({
          collectionName: 'spreads',
          ...request
        })

        if (spreads) {
          for (const spread of spreads) {
            await commit('setSpread', { spreadId: spread.id, data: spread.data })
          }
        } else {
          await commit('setSpread', { spreadId: null, data: null })
        }

        commit('resetSpreads')
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'fetchSpreads', params: {} })
      } finally {
        t.stop()
      }
    },
    async saveSpread({ commit, dispatch, getters }, { spreadId, sum, name, accounts }) {
      if (!getters.online) { return }

      if (!sum || !name || !accounts || !accounts.length) {
        dispatch('setError', localizeFilter('Error'))
        return false
      }

      if (spreadId && (!getters.spreads || !getters.spreads[spreadId])) {
        dispatch('setError', localizeFilter('Error'))
        return false
      }

      if (!getters.getLimit('spreads') || (!spreadId && getters.spreads && Object.keys(getters.spreads).length >= 5)) {
        commit('showPopUp', { name: 'PayWall' })
        return
      }

      let canSave = false
      if (!spreadId) {
        canSave = true
      } else if (getters.spreads && getters.spreads[spreadId]) {
        const spread = getters.spreads[spreadId]
        if (!canSave && getters.spreads[spreadId].name && getters.spreads[spreadId].name !== name) { canSave = true }
        if (!canSave && +spread.sum !== +sum) { canSave = true }
        if (!canSave && spread.accounts.length !== accounts.length) { canSave = true }
        if (!canSave) {
          for (const i in accounts) {
            const line = this.lines[i]
            const savedLine = spread.accounts[i]
            if (line && line.accountId !== savedLine.accountId) {
              canSave = true
              break
            }
            if (line && (line.cardId || savedLine.cardId) && line.cardId !== savedLine.cardId) {
              canSave = true
              break
            }
            if (line && line.comment !== savedLine.comment) {
              canSave = true
              break
            }
            if (line && line.sum !== savedLine.sum) {
              canSave = true
              break
            }

            let lineTagsString
            if (line && line.tags && line.tags.length) { lineTagsString = line.tags.join('-') }
            let savedLineTagsString
            if (savedLine.tags && savedLine.tags.length) { savedLineTagsString = savedLine.tags.join('-') }
            if (lineTagsString !== savedLineTagsString) {
              canSave = true
              break
            }
          }
        }
      }

      if (!canSave) { return }

      const { toastify } = useNotifications()

      if (getters.processing) {
        toastify.error(localizeFilter('Wait') + '...')
        return
      }

      const t = tracePerformance('saveSpread')
      t.start()

      const syncTimestamp = new Date()
      let toastId

      try {
        commit('setProcessing', true)
        toastId = toastify.warning(localizeFilter('Saving') + '...', { timeout: null })

        const increaseTotalNumber = spreadId ? 0 : 1
        const newSpreadId = createId('spreads')

        const spreadData = {
          sum: +sum,
          name: name.trim(),
          accounts: [],
          timestamp: syncTimestamp,
          owner: getUid()
        }

        for (const i in accounts) {
          const account = accounts[i]
          let accountInfo = {}

          accountInfo.accountId = account.accountId
          if (account.cardId && getters.getLimitNumber('maxCards')) { accountInfo.cardId = account.cardId }
          if (account.comment) { accountInfo.comment = account.comment }
          if (account.sum) { accountInfo.sum = account.sum }
          if (account.tags && account.tags.length) {
            accountInfo.tags = account.tags
          }

          spreadData.accounts.push(accountInfo)
        }

        const batchArray = await createBatch([
          {
            timestamp: syncTimestamp,
            type: 'set',
            place: 'spreads',
            id: spreadId || newSpreadId,
            data: spreadData,
            merge: true,
            logAction: spreadId ? 'edited' : 'added',
            updateStats: spreadId ? 0 : 1,
          }
        ])

        await dispatch('subscribeToLogs', syncTimestamp)

        for (const batch of batchArray) { await batch.commit() }

        await dispatch('spreadEdited', { spreadId: spreadId || newSpreadId, data: spreadData, increaseTotalNumber })
        toastify.replace(toastId, localizeFilter('Saved'), 'success')
        commit('setChosenSpreadId', spreadId ? spreadId : newSpreadId)
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'saveSpread', params: { spreadId, sum, name, accounts } })
        return false
      } finally {
        commit('setProcessing', false)
        t.stop()
      }
    },
    async spreadEdited({ commit, dispatch }, { spreadId, data, increaseTotalNumber = 0 }) {
      if (!spreadId || !data) {
        dispatch('setError', localizeFilter('Error'))
        return false
      }

      await commit('setSpread', { spreadId, data })

      if (increaseTotalNumber) {
        await commit('increaseTotalNumberOf', { field: 'spreads', number: increaseTotalNumber })
      }

      commit('resetSpreads')
    },
    async deleteSpread({ commit, dispatch, getters }, { spreadId = null }) {
      if (!getters.online) { return }

      const { toastify } = useNotifications()

      if (getters.processing) {
        toastify.error(localizeFilter('Wait') + '...')
        return
      }

      if (!spreadId || !getters.spreads || !getters.spreads[spreadId]) {
        dispatch('setError', localizeFilter('Error'))
        return false
      }

      const t = tracePerformance('deleteSpread')
      t.start()

      const syncTimestamp = new Date()
      let toastId

      try {
        commit('setProcessing', true)
        toastId = toastify.warning(localizeFilter('Deleting') + '...', { timeout: null })

        const batchArray = await createBatch([
          {
            timestamp: syncTimestamp,
            type: 'delete',
            place: 'spreads',
            id: spreadId,
            updateStats: -1
          }
        ])

        await dispatch('subscribeToLogs', syncTimestamp)

        for (const batch of batchArray) { await batch.commit() }

        await dispatch('spreadDeleted', spreadId)

        toastify.replace(toastId, localizeFilter('Deleted'), 'success')
        logAnalyticsEvent('spreadDeleted')
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'deleteSpread', params: { spreadId }, toastId })
        return false
      } finally {
        commit('setProcessing', false)
        t.stop()
      }
    },
    async deleteAllSpreads({ commit, dispatch, getters }) {
      if (!getters.online) { return }

      const { toastify } = useNotifications()

      if (getters.processing) {
        toastify.error(localizeFilter('Wait') + '...')
        return
      }

      if (!getters.userStats('spreads')) {
        dispatch('setError', localizeFilter('Error'))
        return false
      }

      const t = tracePerformance('deleteAllSpreads')
      t.start()

      const syncTimestamp = new Date()
      let toastId

      try {
        commit('setProcessing', true)
        toastId = toastify.warning(localizeFilter('Deleting') + '...', { timeout: null })

        const spreadsToDelete = []

        const request = { wheres: [['owner', '==', getUid()]] }

        const spreads = await getFirestoreDocs({
          collectionName: 'spreads',
          ...request
        })

        if (spreads) {
          for (const spread of spreads) {
            if (!spreadsToDelete.includes(spread.id)) { spreadsToDelete.push(spread.id) }
          }
        }

        if (!spreadsToDelete.length) {
          toastify.replace(toastId, localizeFilter('Deleted'), 'success')
          return true
        }

        const batchData = []

        for (const spreadId of spreadsToDelete) {
          batchData.push({
            timestamp: syncTimestamp,
            type: 'delete',
            place: 'spreads',
            id: spreadId
          })
        }

        if (spreadsToDelete.length) {
          batchData.push({
            timestamp: syncTimestamp,
            type: 'update',
            place: 'userStats',
            id: getUid(),
            data: {
              spreads: firestoreIncrement(spreadsToDelete.length * -1)
            },
            noLogs: true
          })
        }

        const batchArray = await createBatch(batchData)

        await dispatch('subscribeToLogs', syncTimestamp)

        for (const batch of batchArray) { await batch.commit() }

        for (const spreadId of spreadsToDelete) {
          await dispatch('spreadDeleted', spreadId)
        }

        toastify.replace(toastId, localizeFilter('Deleted'), 'success')
        logAnalyticsEvent('allSpreadsDeleted')
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'deleteAllSpreads', params: {}, toastId })
        return false
      } finally {
        commit('setProcessing', false)
        t.stop()
      }
    },
    async spreadDeleted({ commit, dispatch }, spreadId) {
      if (!spreadId) {
        dispatch('setError', localizeFilter('Error'))
        return false
      }

      await commit('deleteSpreadFromStore', spreadId)
      await commit('increaseTotalNumberOf', { field: 'spreads', number: -1 })
      commit('setChosenSpreadId', null)
      commit('resetSpreads')
    },
    openPutToAccounts({ commit, getters }) {
      if (!getters.getLimit('spreads')) {
        commit('showPopUp', { name: 'PayWall' })
        return
      }

      router.push({ name: 'PutToAccounts' }).catch(() => { })
    },
    showSavedSpreadsClicked({ commit, getters }) {
      if (!getters.getLimit('spreads')) {
        commit('showPopUp', { name: 'PayWall' })
        return
      }

      if (!getters.spreads || !Object.keys(getters.spreads).length) {
        const { toastify } = useNotifications()
        toastify.info(localizeFilter('NoSavedSpreads'))
        return
      }

      commit('showPopUp', { name: 'SavedSpreads' })

      logAnalyticsEvent('showSavedSpreadsClicked', { from: 'left-menu' })
    },
    showSaveSpreadsClicked({ commit, getters }, { spreadId = null, from }) {
      if (!getters.getLimit('spreads') || (!spreadId && (getters.spreads && Object.keys(getters.spreads).length >= 5))) {
        commit('showPopUp', { name: 'PayWall' })
        return
      }

      if (!getters.canSaveSpread) { return }

      commit('showPopUp', { name: 'SaveSpread', incomingData: { spreadId } })

      if (from) { logAnalyticsEvent('showSaveSpreadsClicked', { from }) }
    }
  },
  getters: {
    spreads: s => s.spreads,
    spreadsArr: (s, getters) => {
      if (getters.spreadsReset) {
        //
      }

      let answer = []

      if (s.spreads && Object.keys(s.spreads).length) {
        answer = Object.keys(s.spreads)
      }

      if (answer.length) {
        answer.sort((a, b) => {
          const nameA = s.spreads[a].name || a
          const nameB = s.spreads[b].name || b
          return nameA.localeCompare(nameB)
        })
      }

      return answer
    },
    spreadsReset: s => s.spreadsReset,
    chosenSpreadId: s => s.chosenSpreadId,
    canSaveSpread: s => s.canSaveSpread,
    spreadsDate: s => s.spreadsDate,
    spreadLines: s => s.spreadLines,
    spreadsSum: s => s.spreadsSum
  }
}