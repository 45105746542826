import { getFirestoreDoc } from '@/firebase/firestore'
import { tracePerformance } from '@/firebase/performance'
import { fetchUrl } from '@/helpers/fetch'

export default {
  state: {
    rates: null,
    nextRatesUpdate: null
  },
  mutations: {
    setRates(state, rates) {
      state.rates = rates
    },
    setNextRatesUpdate(state, dateUnix) {
      state.nextRatesUpdate = dateUnix * 1000
    }
  },
  actions: {
    async fetchRatesKey({ dispatch }) {
      const t = tracePerformance('fetchRatesKey')
      t.start()

      try {
        const answer = await getFirestoreDoc('settings', 'exchangerate-apicom')
        if (answer) { return answer.key }
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'fetchRatesKey', params: {} })
      } finally {
        t.stop()
      }
    },
    async fetchRates({ commit, dispatch, getters }, skipWaiting) {
      if (!skipWaiting) {
        if (getters.rates) {
          if (getters.nextRatesUpdate && getters.nextRatesUpdate > Date.now()) {
            return
          }
        }
      }

      if (!getters.getLimit('rates')) { return }
      if (!getters.defaultCurrency) { return }
      if (process.env.NODE_ENV !== 'production') { return }

      const t = tracePerformance('fetchRates')
      t.start()

      try {
        const key = await dispatch('fetchRatesKey')
        if (!key) { return }
        await fetchUrl('https://v6.exchangerate-api.com/v6/' + key + '/latest/' + getters.defaultCurrency)
          .then(async res => {
            if (res.ok) {
              const answer = await res.json()
              if (answer.result === 'success') {
                if (answer.time_next_update_unix) {
                  commit('setNextRatesUpdate', answer.time_next_update_unix)
                }

                if (answer.conversion_rates) {
                  await commit('setRates', answer.conversion_rates)
                }
              }
            }
          })

      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'fetchRates', params: {} })
      } finally {
        t.stop()
      }
    }
  },
  getters: {
    rates: s => s.rates,
    nextRatesUpdate: s => s.nextRatesUpdate
  }
}