import { fetchUrl } from '@/helpers/fetch'

export async function sendToChat(text) {
  if (!text) { return }

  try {
    await fetchUrl(`https://api.telegram.org/bot${process.env.VUE_APP_TG_BOT_TOKEN}/sendMessage`, {
      method: "POST",
      headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      body: JSON.stringify({
        chat_id: process.env.VUE_APP_TG_CHAT_ID,
        text: text
      })
    })
  } catch (error) {
    //
  }
}