<template>
  <div
    :class="{
      'green-to-blue-page-gradient': !getLimit('setAppColor') || !appColor || appColor === 'green-to-blue',
      'blue-to-green-page-gradient': getLimit('setAppColor') && appColor === 'blue-to-green',
      'green-to-gray-page-gradient': getLimit('setAppColor') && appColor === 'green-to-gray',
      'blue-to-gray-page-gradient': getLimit('setAppColor') && appColor === 'blue-to-gray',
      'green-to-yellow-page-gradient': getLimit('setAppColor') && appColor === 'green-to-yellow',
      'blue-to-red-page-gradient': getLimit('setAppColor') && appColor === 'blue-to-red',
      'indigo-to-green-page-gradient': getLimit('setAppColor') && appColor === 'indigo-to-green',
      'indigo-to-red-page-gradient': getLimit('setAppColor') && appColor === 'indigo-to-red',
      'purple-to-red-page-gradient': getLimit('setAppColor') && appColor === 'purple-to-red',
      'purple-to-pink-page-gradient': getLimit('setAppColor') && appColor === 'purple-to-pink',
      'gray-to-gray-page-gradient': getLimit('setAppColor') && appColor === 'gray-to-gray'
    }"
  >
    <AppLoader
      v-if="appLoading"
      :steps="steps"
      :completed-steps="completedSteps"
      @done="loadingFinished"
    />

    <div v-else>
      <LeftMenu />
      <Drawer :key="locale" />

      <HeaderPannel />

      <IosBanner
        v-if="showIosBanner"
        @close-banner="closeIosBanner()"
      />

      <AddTransactionButton
        v-if="addTransactionAllowed && online && !processing"
      />

      <main>
        <router-view class="app-view"/>
      </main>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { mapGetters } from 'vuex'
import { getUid } from '@/firebase/auth'
import { sendToChat } from '@/helpers/chat'
import { readLocalStorageData, readLocalStorageString, setLocalStorage } from '@/helpers/localstorage'
import useNotifications from '@/composables/useNotifications'

export default {
  name: 'PrivateLayout',
  setup () {
    const { toastify } = useNotifications()
    return { toastify }
  },
  components: {
    AddTransactionButton: defineAsyncComponent(() => import('@/components/views/Transactions/AddTransactionButton')),
    AppLoader: defineAsyncComponent(() => import('@/components/UI/loaders/AppLoader')),
    Drawer: defineAsyncComponent(() => import('@/components/navigation/Drawer')),
    HeaderPannel: defineAsyncComponent(() => import('@/components/app/HeaderPannel')),
    IosBanner: defineAsyncComponent(() => import('@/components/app/banners/IosBanner')),
    LeftMenu: defineAsyncComponent(() => import('@/components/navigation/LeftMenu'))
  },
  data: () => ({
    appLoading: false,
    completedSteps: 0,
    steps: 13,
    showIosBanner: false,
    maintenanceToast: null,
    maintenanceInterval: null
  }),
  computed: {
    ...mapGetters([
      'appColor',
      'country',
      'defaultAccount',
      'firebaseQuotaReached',
      'getLimit',
      'locale',
      'maintenance',
      'online',
      'processing',
      'remoteConfig_platform',
      'transactions',
      'userStats'
    ]),
    route () {
      return this.$route.name
    },
    addTransactionAllowed () {
      return this.$route.meta.addTransactionAllowed
    }
  },
  watch: {
    online (value) {
      if (!value && this.appLoading) {
        this.$router.push({ name: 'Offline' }).catch((e) => { console.log(e)})
      }
    },
    maintenance (value) {
      if (value) {
        if (this.maintenanceInterval) {
          clearInterval(this.maintenanceInterval)
          this.maintenanceInterval = null
        }

        this.removeMaintenanceToast()

        if (value === true) {
          this.$router.push({ name: 'Maintenance' }).catch(() => { })
        } else {
          let textStart = this.$filters.localizeFilter('MaintenanceIn') + ': '
          let textCounter = this.$filters.localizeFilter('Counting').toLowerCase() + '...'

          this.maintenanceToast = this.toastify.info(textStart + textCounter, {
            timeout: null,
            eternal: true
          })

          const closeTimeNum = +value

          this.maintenanceInterval = setInterval(() => {
            const nowNum = Date.now()
            let difference = closeTimeNum - nowNum
            if (difference <= 0) {
              if (this.maintenanceToast) {
                this.toastify.remove(this.maintenanceToast)
                this.maintenanceToast = this.toastify.info(this.$filters.localizeFilter('MaintenanceStarting'), {
                  timeout: null,
                  eternal: true
                })
              }

              if (this.maintenanceInterval) {
                clearInterval(this.maintenanceInterval)
                this.maintenanceInterval = null
              }
            } else {
              textCounter = ''
              if (difference > (1000 * 60 * 60)) {
                const hours = Math.floor(difference / (1000 * 60 * 60))
                if (hours < 10) {
                  textCounter = textCounter + '0'
                }
                textCounter = textCounter + hours + ':'
                difference = difference - (hours * (1000 * 60 * 60))
              }

              if (difference > (1000 * 60)) {
                const minutes = Math.floor(difference / (1000 * 60))
                if (minutes < 10) {
                  textCounter = textCounter + '0'
                }
                textCounter = textCounter + minutes + ':'
                difference = difference - (minutes * (1000 * 60))
              }

              const seconds = Math.floor(difference / 1000)
              if (seconds < 10) {
                textCounter = textCounter + '0'
              }
              textCounter = textCounter + seconds

              let text = textStart + textCounter
              if (this.maintenanceToast) {
                this.toastify.remove(this.maintenanceToast)
                this.maintenanceToast = this.toastify.info(text, {
                  timeout: null,
                  eternal: true
                })
              }
            }
          }, 1000)
        }
      } else {
        this.removeMaintenanceToast()
      }
    }
  },
  created() {
    this.createAppData()
  },
  async beforeUnmount() {
    await this.$store.dispatch('unsubscribeAll')
  },
  methods: {
    async createAppData () {
      this.appLoading = true

      this.completedSteps = 0
      if (!this.online) { return }
      await require('@/views/Offline.vue')

      this.completedSteps = 1
      if (!this.online) { return }
      const query = await this.$route.query
      if (query && query.show) {
        await this.$store.dispatch('setShowPopUp', query.show)

        const newQuery = {}
        for (const key in query) {
          if (key !== 'show') {
            newQuery[key] = query[key]
          }
        }

        this.$router.replace({ query: newQuery }).catch(() => {})
      }

      this.completedSteps = 2
      if (!this.online) { return }
      this.setShowIosBanner()

      this.completedSteps = 3
      if (!this.online) { return }
      await this.$store.dispatch('subscribeToMaintenance')

      this.completedSteps = 4
      if (!this.online) { return }
      if (!this.appColor || !this.defaultCurrency || !this.country || !this.$store.getters.darkMode) {
        const appSettingsAnswer = await this.$store.dispatch('fetchUserSettings')
        if (appSettingsAnswer === 'userNotFound') {
          this.$store.dispatch('setError', this.$filters.localizeFilter('UserNotFound'))
          await this.$store.dispatch('deleteUserAndLogout', null)
          sendToChat('Пользователь не найден:\n' + getUid())
          this.$router.push({ name: 'Login', query: { type: 'signup' } }).catch(() => { })
          return
        }
      }

      this.completedSteps = 5
      if (!this.online) { return }
      if (!this.$store.getters.logsUnsubscribe) {
        this.$store.dispatch('subscribeToLogs', null)
      }

      this.completedSteps = 6
      if (!this.online) { return }
      if (!this.$store.getters.tarif) {
        await this.$store.dispatch('fetchUserTarif')
      }

      this.completedSteps = 7
      if (!this.online) { return }
      if (!this.$store.getters.loadedAll('userStats')) {
        await this.$store.dispatch('fetchUserStats')
      }

      this.completedSteps = 8
      if (!this.online) { return }
      if (!this.$store.getters.userName || !this.$store.getters.userPic) {
        await this.$store.dispatch('fetchUserProfile')
      }

      this.completedSteps = 9
      if (!this.online) { return }
      if (!Object.keys(this.defaultAccount).length) {
        await this.$store.dispatch('fetchDefaultAccount')
      }

      this.completedSteps = 10
      if (!this.online) { return }
      if (this.$store.getters.userStats('neverLoggedIn')) {
        await this.$store.dispatch('createInitialData')
      }

      this.completedSteps = 11
      if (!this.online) { return }
      if (!this.$store.getters.newsUnsubscribe && this.userStats('newsRead')) {
        this.$store.dispatch('subscribeToNews')
      }

      this.completedSteps = 12
      if (!this.online) { return }
      const lsSessionId = await readLocalStorageString('blue-money-box-com-sessionId-' + getUid())
      if (lsSessionId) {
        this.$store.commit('setSessionId', lsSessionId)
      } else {
        await this.$store.dispatch('createSessionId')
      }

      this.completedSteps = 13
    },
    async setShowIosBanner() {
      const localStorageName = 'blue-money-box-com-hideIosBanner-' + getUid()
      let ls = await readLocalStorageData(localStorageName)
      if (!ls) {
        setLocalStorage({
          name: localStorageName,
          data: {
            timestamp: Date.now()
          }
        })
        return
      }

      if (!ls.hideIosBanner) {
        const userAgent = navigator.userAgent.toLowerCase()
        if (getUid() && userAgent.indexOf('safari') >= 0 && userAgent.indexOf('chrome') < 0 && userAgent.indexOf('edge') < 0 && userAgent.indexOf('edgios') < 0 && userAgent.indexOf('yabrowser') < 0 && userAgent.indexOf('crios') < 0 && userAgent.indexOf('fxios') < 0 && (this.remoteConfig_platform === 'iOS')) {
          this.showIosBanner = true
        }
      }
    },
    async closeIosBanner() {
      this.showIosBanner = false

      const localStorageName = 'blue-money-box-com-hideIosBanner-' + getUid()
      let ls = await readLocalStorageData(localStorageName)
      if (!ls) {
        setLocalStorage({
          name: localStorageName,
          data: {
            timestamp: Date.now(),
            hideIosBanner: Date.now()
          }
        })
        return
      } else {
        ls.hideIosBanner = Date.now()
        setLocalStorage({ name: localStorageName, data: ls })
      }
    },
    loadingFinished() {
      setTimeout(() => {
        this.appLoading = false
        this.completedSteps = 0
      }, 250)
    },
    removeMaintenanceToast() {
      if (this.maintenanceToast) {
        this.toastify.remove(this.maintenanceToast)
      }
    }
  }
}
</script>