import { createBatch, createId, createLogData, firestoreIncrement, firestoreDeleteField, setFirestoreDoc } from '@/firebase/firestore'
import { setSocialProvider, updatePasswordFirebase, unlinkProvider, signOutUser, sendPasswordResetEmailFunction, sendEmailVerificationFunction, getUserRigestred, setLanguageCode, getUserEmail, getUid, emailVerified, signInWithPopupFirebase, signInWithRedirectFirebase, reauthenticateWithCredentialFunction, linkWithRedirectFirebase, linkWithPopupFirebase, deleteFirebaseUser, getAdditionalUserInfoFirebase, setUserPersistance, createUserWithEmailAndPasswordFunction, signInWithEmailAndPasswordFunction, getUserProviderData } from '@/firebase/auth'
import { uploadFileUrlToStorage } from '@/firebase/storage'
import { tracePerformance } from '@/firebase/performance'
import { logAnalyticsEvent } from '@/firebase/analytics'
import { sendToChat } from '@/helpers/chat'
import { removeLocalStorage } from '@/helpers/localstorage'
import localizeFilter from '@/filters/localize.filter'
import countries from '@/libraries/countries'
import useNotifications from '@/composables/useNotifications'
import { fetchUrl } from '@/helpers/fetch'
import { isImageFile } from '@/helpers/fileinfo'
import router from '../router'
import { checkValues } from '@/settings/app-limits'

const usedSocialProviders = ['google.com', 'facebook.com', 'github.com']

export default {
  state: {
    userName: null,
    userPic: null,
    userProvidersReset: 0
  },
  mutations: {
    setUserName(state, name) {
      state.userName = name
    },
    setUserPic(state, url) {
      state.userPic = url
    },
    resetUserProviders(state) {
      state.userProvidersReset = Date.now()
    },
    clearInfo(state) {
      state.userName = null
      state.userPic = null
    }
  },
  actions: {
    async createInitialData({ commit, dispatch, getters }) {
      const t = tracePerformance('createInitialData')
      t.start()

      const timestamp = new Date()

      try {
        const batchData = []

        const country = getters.country || 'USA'
        let accountCurrency = 'USD'
        if (countries[country] && countries[country].currencyCode) {
          accountCurrency = countries[country].currencyCode
        }

        let accountColor = 'white'
        if (accountCurrency === 'USD') {
          accountColor = 'green'
        } else if (accountCurrency === 'EUR') {
          accountColor = 'blue'
        }

        const accountsNames = ['BankAccount', 'CashAccount']
        const accountsForStore = []
        for (const accountName of accountsNames) {
          const accountId = createId('accounts')

          const accountData = {
            active: true,
            name: localizeFilter(accountName).substr(0, checkValues('accountName', 'max')),
            sum: 0,
            currencyCode: accountCurrency,
            timestamp,
            color: accountColor,
            owner: getUid()
          }

          batchData.push({
            timestamp: new Date(+getters.syncTimestamp - 1),
            type: 'set',
            place: 'accounts',
            id: accountId,
            data: accountData,
            logType: 'appCreated'
          })

          accountsForStore.push({ accountId, data: accountData })
        }

        const tags = [
          {
            data: {
              color: 'yellow',
              icon: 'shop',
              name: localizeFilter('Food')
            }
          },
          {
            data: {
              color: 'blue',
              icon: 'home',
              name: localizeFilter('Home')
            }
          },
          {
            data: {
              color: 'indigo',
              icon: 'travel',
              name: localizeFilter('Travels'),
              travelStartDate: new Date(timestamp.getFullYear(), timestamp.getMonth(), timestamp.getDate() - 10),
              travelEndDate: new Date(timestamp.getFullYear(), timestamp.getMonth(), timestamp.getDate() + 5)
            }
          },
          {
            data: {
              color: 'pink',
              name: localizeFilter('Entertainment')
            }
          },
          {
            data: {
              color: 'green',
              icon: 'healing',
              name: localizeFilter('Health')
            }
          }
        ]

        for (const tag of tags) {
          tag.tagId = createId('tags')
          tag.data.active = true
          tag.data.owner = getUid()
          tag.data.timestamp = timestamp
          tag.data.sums = {}

          batchData.push({
            timestamp: new Date(+getters.syncTimestamp - 1),
            type: 'set',
            place: 'tags',
            id: tag.tagId,
            data: tag.data,
            logType: 'appCreated'
          })
        }

        batchData.push({
          timestamp: new Date(+getters.syncTimestamp - 1),
          type: 'update',
          place: 'userStats',
          id: getUid(),
          data: {
            accounts: firestoreIncrement(accountsNames.length),
            tags: firestoreIncrement(tags.length),
            neverLoggedIn: firestoreDeleteField
          },
          noLogs: true
        })

        const batchArray = await createBatch(batchData)

        for (const batch of batchArray) { await batch.commit() }

        commit('deleteFieldFromUserStats', 'neverLoggedIn')

        for (const account of accountsForStore) {
          await commit('setAccount', account)
        }

        for (const tag of tags) {
          await commit('addTag', tag)
        }

        commit('increaseTotalNumberOf', { field: 'accounts', number: 2 })
        commit('increaseTotalNumberOf', { field: 'tags', number: 5 })
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'createInitialData', params: {} })
        return false
      } finally {
        t.stop()
      }
    },
    async login({ commit, dispatch, getters }, {
      type = null,
      providerId = null,
      email = null,
      password = null,
      logMeOut = true,
      agree = false,
      country = null
    }) {
      await commit('clearInfo')

      if (!providerId || !type) {
        dispatch('setError', localizeFilter('Error'))
        return false
      }

      if (providerId === 'password' && (!email || !password)) {
        dispatch('setError', localizeFilter('Error'))
        return false
      }

      if (type === 'signup') {
        if (!agree || !country) {
          dispatch('setError', localizeFilter('Error'))
          return false
        }
      }

      const t = tracePerformance('login')
      t.start()

      try {
        commit('setProcessing', true)

        if (logMeOut) {
          await setUserPersistance('SESSION')
        } else {
          await setUserPersistance('LOCAL')
        }

        const locale = getters.locale

        await setLanguageCode(locale)

        let provider
        let result

        if (providerId === 'password') {
          if (type === 'login') {
            result = await signInWithEmailAndPasswordFunction(email, password)
          } else if (type === 'signup') {
            result = await createUserWithEmailAndPasswordFunction(email, password)
          }
        } else {
          provider = await setSocialProvider(providerId, getters.locale)

          const handleError = async (error) => {
            try {
              if (error.code === 'auth/popup-blocked') {
                await signInWithRedirectFirebase(provider)
                  .then(
                    res => result = res,
                    async err => await handleError(err)
                  )
              } else if (error.code === 'auth/account-exists-with-different-credential') {
                if (type === 'login') {
                  dispatch('setError', localizeFilter('AddSocialFromProfile'))
                } else if (type === 'signup') {
                  dispatch('setError', localizeFilter('EmailAlreadyInUse'))
                }

                return
              } else {
                dispatch('setError', error)
                return
              }
            } catch (e) {
              dispatch('setError', e)
              return
            }
          }

          if (usedSocialProviders.includes(providerId)) {
            const errorAnswer = await signInWithPopupFirebase(provider)
              .then(
                res => { result = res },
                async err => await handleError(err)
              )

            if (errorAnswer) { return errorAnswer }
          }
        }

        if (providerId !== 'password' && !result) { return null }

        const afterLoginAnswer = await dispatch('afterLogin', { type, providerId, result, country })
        return afterLoginAnswer
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'login', params: { type, email, logMeOut, providerId, agree, country } })
      } finally {
        commit('setProcessing', false)
        t.stop()
      }
    },
    async afterLogin({ commit, dispatch, getters }, { providerId = null, result = null, country = null, type = null }) {
      if (!providerId || !type) { return false }
      if (providerId !== 'password' && !result) { return false }

      const locale = getters.locale
      let email

      try {
        if (type === 'signup') {
          if (result) {
            if (!result.user || !result.user.email) {
              await dispatch('deleteUserAndLogout', result.user)
              return 'emailNotProvided'
            }

            email = result.user.email
          }
        }

        const providerData = {}

        if (providerId !== 'password') {
          const additionalUserInfo = await getAdditionalUserInfoFirebase(result)
          if (!additionalUserInfo) { return false }
          if (additionalUserInfo.username) { providerData.username = additionalUserInfo.username }
          if (additionalUserInfo.profile) { providerData.profile = additionalUserInfo.profile }

          if (type === 'login') {
            if (additionalUserInfo.isNewUser) {
              await dispatch('deleteUserAndLogout', result.user)
              return 'userNotRegistred'
            }
          } else if (type === 'signup') {
            if (!additionalUserInfo.isNewUser) { return 'notNewUser' }
          }
        }

        let syncTimestamp = new Date()
        if (type === 'signup') {
          const userRigestred = await getUserRigestred()
          if (userRigestred) { syncTimestamp = new Date(userRigestred) }
        }

        if (!getUid()) { return false }

        if (type === 'signup') { sendToChat('Зарегистрирован новый пользователь:\n' + getUid() + '\nСтрана: ' + country) }

        const verified = await emailVerified()

        const batchData = []

        if (type === 'login') {
          if (!verified) {
            await dispatch('verifyEmail')
            await dispatch('logout', false)
            return 'verifyEmail'
          }

          await dispatch('createSessionId')

          batchData.push({
            timestamp: syncTimestamp,
            type: 'set',
            place: 'logs',
            logPlace: 'auth',
            logAction: 'login',
            id: getters.sessionId,
            logData: {
              navigatorLanguage: navigator.language,
              navigatorLanguages: navigator.languages,
              navigatorUserAgent: navigator.userAgent,
              providerId: providerId,
              providerData: providerData
            }
          })
        } else if (type === 'signup') {
          batchData.push({
            timestamp: syncTimestamp,
            type: 'update',
            place: 'stats',
            id: 'users',
            data: {
              numberOfUsersTotal: firestoreIncrement(1)
            },
            noLogs: true
          })

          batchData.push({
            timestamp: syncTimestamp,
            type: 'update',
            place: 'stats',
            id: 'countries',
            data: {
              [country]: firestoreIncrement(1)
            },
            noLogs: true
          })

          const now = new Date()

          const tarifData = {
            tarifId: 'TarifMax',
            timestamp: syncTimestamp,
            validTill: new Date(now.getFullYear(), now.getMonth(), now.getDate() + 90, 23, 59, 59),
            paymentId: 'userCreated[WebApp]'
          }

          batchData.push({
            timestamp: syncTimestamp,
            type: 'set',
            place: 'userTarif',
            id: getUid(),
            data: tarifData,
            noLogs: true
          })

          batchData.push({
            timestamp: syncTimestamp,
            type: 'set',
            place: 'userSettings',
            id: getUid(),
            data: {
              appColor: 'green-to-blue',
              defaultCurrency: null,
              locale,
              country
            },
            noLogs: true
          })

          let userName = null
          let userPic = null

          if (providerData.profile) {
            if (providerId === 'google.com') {
              if (providerData.profile['name']) {
                userName = providerData.profile['name']
              } else if (providerData.profile['given_name'] || providerData.profile['family_name']) {
                userName = ''
                if (providerData.profile['given_name']) { userName = providerData.profile['given_name'] }
                if (providerData.profile['family_name']) {
                  if (userName) { userName = userName + ' ' }
                  userName = userName + providerData.profile['family_name']
                }
              }

              if (providerData.profile['picture']) {
                const answer = await uploadFileUrlToStorage('userpic', null, providerData.profile['picture'])
                if (answer) { userPic = answer }
              }
            } else if (providerId === 'facebook.com') {
              if (providerData.profile['name']) {
                userName = providerData.profile['name']
              } else if (providerData.profile['first_name'] || providerData.profile['last_name']) {
                userName = ''
                if (providerData.profile['first_name']) { userName = providerData.profile['first_name'] }
                if (providerData.profile['last_name']) {
                  if (userName) { userName = userName + ' ' }
                  userName = userName + providerData.profile['last_name']
                }
              }

              if (providerData.profile.picture && providerData.profile.picture.data && providerData.profile.picture.data.url) {
                const answer = await uploadFileUrlToStorage('userpic', null, providerData.profile.picture.data.url)
                if (answer) { userPic = answer }
              }
            } else if (providerId === 'github.com') {
              if (providerData.profile['name']) {
                userName = providerData.profile['name']
              }

              if (providerData.profile['avatar_url']) {
                const answer = await uploadFileUrlToStorage('userpic', null, providerData.profile['avatar_url'])
                if (answer) { userPic = answer }
              }
            }
          }

          batchData.push({
            timestamp: syncTimestamp,
            type: 'set',
            place: 'userProfile',
            id: getUid(),
            data: {
              name: userName,
              userPic,
              email,
              created: syncTimestamp
            },
            noLogs: true
          })

          batchData.push({
            timestamp: syncTimestamp,
            type: 'set',
            place: 'userStats',
            id: getUid(),
            data: {
              accounts: 0,
              moneyBoxes: 0,
              banks: 0,
              cards: 0,
              newsRead: new Date(+syncTimestamp - 10000),
              spreads: 0,
              tags: 0,
              tagsCollections: 0,
              transactions: 0,
              neverLoggedIn: true
            },
            noLogs: true
          })

          let accountCurrency = 'USD'
          if (countries[country] && countries[country].currencyCode) {
            accountCurrency = countries[country].currencyCode
          }

          batchData.push({
            timestamp: syncTimestamp,
            type: 'set',
            place: 'logs',
            logPlace: 'auth',
            logType: 'appCreated',
            logAction: 'signup',
            id: getUid(),
            logData: {
              locale: locale,
              country: country,
              tarif: tarifData,
              accountCurrency: accountCurrency,
              providerId: providerId,
              providerData: providerData
            }
          })
        }

        const batchArray = await createBatch(batchData)
        for (const batch of batchArray) { await batch.commit() }

        if (type === 'signup') {
          if (!verified) {
            await dispatch('verifyEmail')
            return 'verifyEmail'
          }

          commit('resetUserProviders')
        }

        await dispatch('subscribeToLogs', syncTimestamp)

        return true
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'afterLogin', params: { providerId, type, country } })
      }
    },
    async deleteUserAndLogout({ dispatch }, user) {
      try {
        await deleteFirebaseUser(user)
        await dispatch('logout', true)
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'deleteUserAndLogout', params: { user: user } })
      }
    },
    async logout({ commit, dispatch, getters }, isNewUser) {
      const t = tracePerformance('logout')
      t.start()

      const syncTimestamp = new Date()

      try {
        if (getUid() && !isNewUser) {
          await setFirestoreDoc('logs', createId('logs'), createLogData({
            timestamp: syncTimestamp,
            place: 'auth',
            action: 'logout',
            type: 'auth',
            id: getters.sessionId,
            data: {}
          }))
        }

        commit('setProcessing', true)

        await dispatch('unsubscribeAll')
        await signOutUser()

        await removeLocalStorage('blue-money-box-com-sessionId-' + getUid())
        await commit('clearInfo')

        if (!isNewUser) {
          const { toastify } = useNotifications()
          toastify.clearNotifications()
        }
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'logout', params: {} })
        return false
      } finally {
        commit('setProcessing', false)
        t.stop()
      }
    },
    async logoutOtherSessions({ dispatch, getters }) {
      const sessionId = getters.sessionId
      if (!sessionId) {
        dispatch('setError', localizeFilter('Error'))
        return false
      }

      const t = tracePerformance('logoutOtherSessions')
      t.start()

      const syncTimestamp = new Date()

      try {
        await dispatch('subscribeToLogs', syncTimestamp)

        await setFirestoreDoc('logs', createId('logs'), createLogData({
          timestamp: syncTimestamp,
          place: 'auth',
          action: 'logout',
          id: sessionId,
          data: {}
        }))

        return true
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'logoutOtherSessions', params: {} })
        return false
      } finally {
        t.stop()
      }
    },
    createCallBackLink({ getters }, { email, page }) {
      if (!email) { return }

      let url = process.env.VUE_APP_APP_URL
      if (page) { url = url + '/' + page }

      const query = 'email=' + email + '&lang=' + getters.locale

      return url + '?' + query
    },
    async passwordRecovery({ commit, dispatch }, { email, page = null }) {
      if (!email) {
        dispatch('setError', localizeFilter('Error'))
        return false
      }

      const t = tracePerformance('passwordRecovery')
      t.start()

      const callBackUrl = await dispatch('createCallBackLink', { email: email, page: page })
      if (!callBackUrl) {
        dispatch('setError', localizeFilter('Error'))
        return false
      }

      try {
        commit('setProcessing', true)
        await sendPasswordResetEmailFunction(email, callBackUrl)
        return true
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'passwordRecovery', params: { email: email, page: page } })
        return false
      } finally {
        commit('setProcessing', false)
        t.stop()
      }
    },
    async reAuthUser({ commit, dispatch }, password) {
      if (!password) {
        dispatch('setError', localizeFilter('Error'))
        return false
      }

      const t = tracePerformance('reAuthUser')
      t.start()

      try {
        commit('setProcessing', true)

        const reauthenticated = await reauthenticateWithCredentialFunction(password)
        if (reauthenticated) { return true }
        return false
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'reAuthUser', params: {} })
        return false
      } finally {
        commit('setProcessing', false)
        t.stop()
      }
    },
    async saveNewPassword({ commit, dispatch, getters }, { currentPassword, newPassword }) {
      if (!getters.online) { return }

      const { toastify } = useNotifications()

      if (getters.processing) {
        toastify.error(localizeFilter('Wait') + '...')
        return
      }

      if (!currentPassword || !newPassword) {
        dispatch('setError', localizeFilter('Error'))
        return false
      }

      const t = tracePerformance('saveNewPassword')
      t.start()

      const syncTimestamp = new Date()
      let toastId

      try {
        toastId = toastify.warning(localizeFilter('Saving') + '...', { timeout: null })

        const reauthenticated = await dispatch('reAuthUser', currentPassword)
        if (!reauthenticated) {
          toastify.remove(toastId)
          return false
        }

        commit('setProcessing', true)

        await updatePasswordFirebase(newPassword)

        await dispatch('subscribeToLogs', syncTimestamp)

        await setFirestoreDoc('logs', createId('logs'), createLogData({
          timestamp: syncTimestamp,
          place: 'auth',
          action: 'logout',
          id: getters.sessionId,
          data: {}
        }))

        toastify.replace(toastId, localizeFilter('PasswordChanged'), 'success')
        logAnalyticsEvent('newPasswordSetFromSettings')
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'saveNewPassword', params: {} })
        return false
      } finally {
        commit('setProcessing', false)
        t.stop()
      }
    },
    async verifyEmail({ dispatch }) {
      try {
        const email = await getUserEmail()
        if (!email) { return }

        const callBackUrl = await dispatch('createCallBackLink', { email: email, page: 'login' })

        const data = {}
        if (callBackUrl) {
          data.url = callBackUrl
        }

        await sendEmailVerificationFunction(data)
        return true
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'verifyEmail', params: {} })
        return false
      }
    },
    async linkSocialProvider({ commit, dispatch, getters }, { providerId = null, logEvent }) {
      if (!providerId) {
        dispatch('setError', localizeFilter('Error'))
        return false
      }

      const { toastify } = useNotifications()
      let toastId

      if (getters.processing) {
        toastify.error(localizeFilter('Wait') + '...')
        return
      }

      const t = tracePerformance('linkSocialProvider')
      t.start()

      try {
        const userEmail = await getUserEmail()
        if (!userEmail) {
          dispatch('setError', localizeFilter('Error'))
          return false
        }

        commit('setProcessing', true)
        toastId = toastify.warning(localizeFilter('Saving') + '...', { timeout: null })

        const provider = await setSocialProvider(providerId, getters.locale)
        let result

        const handleError = async (error) => {
          try {
            if (error.code === 'auth/popup-blocked') {
              await linkWithRedirectFirebase(provider)
                .then(
                  res => result = res,
                  async err => await handleError(err)
                )
            } else {
              dispatch('setError', error)
              return
            }
          } catch (e) {
            dispatch('setError', e)
            return
          }
        }

        if (usedSocialProviders.includes(providerId)) {
          const errorAnswer = await linkWithPopupFirebase(provider)
            .then(
              res => { result = res },
              async err => await handleError(err)
            )

          if (errorAnswer) { return errorAnswer }
        }

        if (!result) { return }

        const answer = await dispatch('afterSocialAccountLinking', { providerId, result })

        toastify.replace(toastId, localizeFilter('Saved'), 'success')
        if (logEvent) { logAnalyticsEvent(logEvent, { providerId }) }
        return answer
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'linkSocialProvider', params: { providerId }, toastId })
      } finally {
        commit('setProcessing', false)
        t.stop()
      }
    },
    async afterSocialAccountLinking({ commit, dispatch, getters }, { providerId = null, result = null }) {
      if (!providerId || !result) { return false }

      try {
        const userEmail = await getUserEmail()
        if (!userEmail) { return false }

        const syncTimestamp = new Date()

        if (!result) { return }

        const additionalUserInfo = await getAdditionalUserInfoFirebase(result)
        if (!additionalUserInfo || !additionalUserInfo.profile || !additionalUserInfo.profile.email) {
          dispatch('setError', localizeFilter('EmailNotProvided'))
          await dispatch('unlinkSocialProvider', providerId)
          return false
        }

        if (additionalUserInfo.profile.email !== userEmail) {
          dispatch('setError', localizeFilter('NotSameEmail'))
          await dispatch('unlinkSocialProvider', providerId)
          return false
        }

        const providerData = {}
        if (additionalUserInfo.profile) { providerData.profile = additionalUserInfo.profile }
        if (additionalUserInfo.username) { providerData.username = additionalUserInfo.username }

        await setFirestoreDoc('logs', createId('logs'), createLogData({
          timestamp: syncTimestamp,
          place: 'auth',
          action: 'socialLogin',
          type: 'auth',
          id: getters.sessionId,
          data: {
            added: providerId,
            providerData
          }
        }))

        commit('resetUserProviders')
        return true
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'afterSocialAccountLinking', params: { providerId: providerId } })
      }
    },
    async unlinkSocialProvider({ commit, dispatch, getters }, providerId = null) {
      if (!providerId) {
        dispatch('setError', localizeFilter('Error'))
        return false
      }

      const t = tracePerformance('unlinkSocialProvider')
      t.start()

      const syncTimestamp = new Date()

      try {
        commit('setProcessing', true)
        unlinkProvider
        await unlinkProvider(providerId).then(async () => {
          await setFirestoreDoc('logs', createId('logs'), createLogData({
            timestamp: syncTimestamp,
            place: 'auth',
            action: 'socialLogin',
            type: 'auth',
            id: getters.sessionId,
            data: {
              deleted: providerId
            }
          }))
        })

        commit('resetUserProviders')

        return true
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'unlinkSocialProvider', params: { providerId } })
        return false
      } finally {
        commit('setProcessing', false)
        t.stop()
      }
    },
    async prepareBlobFromProviderPic(context, providerId) {
      if (!providerId) { return }

      const { toastify } = useNotifications()

      const userPicUrl = await getUserProviderData(providerId, 'photoURL')
      if (!userPicUrl) {
        toastify.error(localizeFilter('Error'))
        return
      }

      let urlResponse
      try {
        urlResponse = await fetchUrl(userPicUrl)
        if (!urlResponse) {
          toastify.error(localizeFilter('Error'))
          return
        }
      } catch (error) {
        toastify.error(localizeFilter('Error'))
        return
      }

      const blob = await urlResponse.blob()
      if (!blob || (+blob.size > checkValues('userPic', 'bytes'))) {
        toastify.error(localizeFilter('CorrectFileSize') + ' ' + Math.floor(checkValues('userPic', 'bytes') / 1024 / 1024) + ' MB')
        return
      }

      if (!isImageFile(blob)) {
        toastify.error(localizeFilter('CorrectPicTypes'))
        return
      }

      return blob
    },
    async deleteUserFunc({ commit, dispatch, getters }, { confirm, password }) {
      if (!getters.online) { return }

      const { toastify } = useNotifications()

      if (getters.processing) {
        toastify.error(localizeFilter('Wait') + '...')
        return
      }

      if (!confirm || !password) {
        dispatch('setError', localizeFilter('Error'))
        return false
      }

      const t = tracePerformance('deleteUserFunc')
      t.start()

      let toastId

      try {
        toastId = toastify.warning(localizeFilter('DeletingUser') + '...', { timeout: null })

        const reauthenticated = await dispatch('reAuthUser', password)
        if (!reauthenticated) {
          toastify.remove(toastId)
          return false
        }

        commit('setProcessing', true)
        await commit('setLoadingPage', true)

        const deletedUserSaved = await dispatch('saveDeletedUser')
        if (!deletedUserSaved) { return false }

        const userDeleted = await deleteFirebaseUser()
        if (!userDeleted) { return false }

        await dispatch('userDeletedCleanUp', getUid())

        toastify.remove(toastId)
        logAnalyticsEvent('userDeleted')
        return true
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'deleteUserFunc', params: {} })
        return false
      } finally {
        commit('setProcessing', false)
        await commit('setLoadingPage', false)
        t.stop()
      }
    },
    async userDeletedCleanUp({ dispatch }, userId) {
      removeLocalStorage('blue-money-box-com-hideIosBanner-' + userId)
      await dispatch('logout', false)
      router.push({ name: 'Login', query: { message: 'userdeleted' } }).catch(() => { })
    },
    async logoutAndGoToLogin({ commit, dispatch }, { isNewUser = false, from }) {
      await dispatch('goHomeAndShowLoader')
      await dispatch('logout', isNewUser)
      await commit('setShowLoader', false)
      router.push({ name: 'Login' }).catch(() => { })
      if (from) { logAnalyticsEvent('logoutAndGoToLogin', { from }) }
    },
    async saveDeletedUser({ commit, dispatch }) {


      const t = tracePerformance('saveDeletedUser')
      t.start()

      const syncTimestamp = new Date()

      try {
        commit('setProcessing', true)
        const batchArray = await createBatch([
          {
            timestamp: syncTimestamp,
            type: 'set',
            place: 'deleteUsers',
            id: getUid(),
            data: {
              timestamp: syncTimestamp
            },
            logPlace: 'auth',
            logAction: 'deleted',
            logData: {}
          }
        ])

        await dispatch('subscribeToLogs', syncTimestamp)

        for (const batch of batchArray) { await batch.commit() }

        sendToChat('Пользователь удален:\n' + getUid())

        return true
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'saveDeletedUser', params: { userId: getUid() } })
        return false
      } finally {
        commit('setProcessing', false)
        t.stop()
      }
    }
  },
  getters: {
    userName: s => s.userName,
    userPic: s => s.userPic,
    userProvidersReset: s => s.userProvidersReset,
    socialProviders: () => ({ hide }) => {
      let providers = [
        {
          id: 'google.com',
          name: 'Google'
        },
        {
          id: 'facebook.com',
          name: 'Facebook'
        },
        {
          id: 'github.com',
          name: 'Github'
        }
      ]

      if (hide && hide.length) {
        for (const soc of hide) {
          providers = providers.filter(social => social.id !== soc)
        }
      }

      return providers
    }
  }
}