function checkDomain() {
  if (process.env.NODE_ENV === 'production') {
    if (document.location.hostname === process.env.VUE_APP_APP_AUTH) {
      document.location.replace(process.env.VUE_APP_APP_URL)
    }

    if (top.document.location.hostname !== document.location.hostname) {
      document.location.replace(process.env.VUE_APP_APP_URL)
    }
  }
}

checkDomain()

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line
  globalThis.__VUE_PROD_HYDRATION_MISMATCH_DETAILS__ = true
}

import { createApp } from 'vue'

import App from './App.vue'

import router from './router'
import store from './store'

import '@/assets/css/index.css';

import 'tailwindcss/tailwind.css'

import { onAuthStateChangedFunction } from '@/firebase/auth'
import { initRemoteConfig } from '@/firebase/remoteConfig'
import { logAnalyticsEvent } from '@/firebase/analytics'

import directives from '@/directives'

import currencyFilter from '@/filters/currency.filter'
import dateFilter from '@/filters/date.filter'
import localizeFilter from '@/filters/localize.filter'
import numberFilter from '@/filters/number.filter'
import totalSumFilter from '@/filters/totalsum.filter'

async function buildApp() {
  try {
    let app

    onAuthStateChangedFunction(() => {
      if (!app) {
        app = createApp(App)

        app.use(router)
        app.use(store)

        directives.forEach(directive => {
          app.directive(directive.name, directive)
        })

        app.config.globalProperties.$filters = {
          currencyFilter, dateFilter, localizeFilter, numberFilter, totalSumFilter
        }

        app.mount('#app')

        initRemoteConfig()

        logAnalyticsEvent('appCreated')
      }
    })
  } catch (e) {
    console.log(e)
  }
}

buildApp()