import { firestoreDB as db } from '@/firebase/firebase'
import { doc, collection, getDoc, getDocs, addDoc, setDoc, updateDoc, query, where, orderBy, limit, startAfter, writeBatch, increment, deleteField, arrayRemove, arrayUnion, Timestamp, onSnapshot } from 'firebase/firestore'
import { getUid } from '@/firebase/auth'
import { prepareDateFields } from '@/utils/prepareDateFields'

export function createId(collectionName) {
  if (!collectionName) { return null }
  return doc(collection(db, collectionName)).id
}

export function createDocRef(collectionName, docName) {
  if (!collectionName || !docName) { return }
  return doc(db, collectionName + '/' + docName)
}

export async function getFirestoreDoc(collectionName, docName) {
  if (!collectionName || !docName || !getUid()) { return }

  const answer = await getDoc(createDocRef(collectionName, docName))
  if (!answer || !answer.exists()) { return }
  return prepareDateFields(answer.data())
}

export async function getFirestoreDocs({ collectionName = null, maxLimit = null, wheres = null, order = null, start = null, needs = [] }) {
  try {
    const request = []

    if (maxLimit) { request.push(limit(+maxLimit)) }

    if (wheres && wheres.length) {
      for (const wh of wheres) {
        request.push(where(wh[0], wh[1], wh[2]))
      }
    }

    if (order && order.length) {
      for (const ord of order) {
        request.push(orderBy(ord[0], ord[1]))
      }
    }

    if (start) { request.push(startAfter(start)) }

    const receivedDocs = await getDocs(query(collection(db, collectionName), ...request))

    let answer

    if (receivedDocs && !receivedDocs.empty) {
      answer = receivedDocs.docs.map(receivedDoc => {
        const obj = { id: receivedDoc.id, data: prepareDateFields(receivedDoc.data()) }

        if (needs.includes('ref')) { obj.ref = receivedDoc.ref }
        if (needs.includes('rawDoc')) { obj.rawDoc = receivedDoc }

        return obj
      })
    }

    return answer
  } catch (error) {
    throw new Error(error)
  }
}

export async function setFirestoreDoc(collectionName, docName, data) {
  if (!collectionName || !docName) { return }

  try {
    await setDoc(createDocRef(collectionName, docName), data)
  } catch (error) {
    throw new Error(error)
  }
}

export async function addFirestoreDoc(collectionName, data) {
  if (!collectionName) { return }

  try {
    await addDoc(collection(db, collectionName), data)
  } catch (error) {
    throw new Error(error)
  }
}

export async function updateFirestoreDoc(collectionName, docName, data) {
  if (!collectionName || !docName) { return }

  try {
    await updateDoc(createDocRef(collectionName, docName), data)
  } catch (error) {
    throw new Error(error)
  }
}

export function onDocSnapshotHandler({ collectionName, docName, successHandler, errorHandler }) {
  return onSnapshot(createDocRef(collectionName, docName), success => successHandler(success), error => errorHandler(error))
}

export function onCollectionSnapshotHandler({ collectionName, wheres = null, order = null, successHandler, errorHandler }) {
  const request = []

  if (wheres && wheres.length) {
    for (const wh of wheres) {
      request.push(where(wh[0], wh[1], wh[2]))
    }
  }

  if (order && order.length) {
    for (const ord of order) {
      request.push(orderBy(ord[0], ord[1]))
    }
  }

  const collectionQuery = query(collection(db, collectionName), ...request)
  return onSnapshot(collectionQuery, success => successHandler(success), error => errorHandler(error))
}

export async function createBatch(values) {
  // values = [
  //   {
  //     timestamp,
  //     type: 'update', // 'set', 'delete'
  //     place: 'transactions',
  //     id: transactionId,
  //     data: {
  //       sum: 123
  //     },
  //     noLogs: false
  //   },
  //   { // for log
  //     timestamp,
  //     type: 'delete',
  //     place: 'logs',
  //     logPlace: 'userStats',
  //     logType: 'appCreated',
  //     logDataInfo: {}
  //     logAction: 'newsRead', // 'edited'
  //     updateStats: 1
  //     id: +getters.syncTimestamp - 1
  //   }
  // ]

  try {
    if (!values || !Array.isArray(values)) { throw new Error('createBatch: no values') }

    let count = 0
    let batchIndex = 0
    const countLimit = 499

    const batchArray = []
    batchArray.push(writeBatch(db))

    const addBatch = () => {
      count++
      if (count > countLimit) {
        batchArray.push(writeBatch(db))
        batchIndex++
        count = 0
      }
    }

    const actions = {
      'set': 'added',
      'update': 'edited',
      'delete': 'deleted'
    }

    for (const value of values) {
      const { id, data, place, type, noLogs, logPlace, logType, logData, logDataInfo, logAction, updateStats, merge } = value
      let { timestamp } = value

      if (!place) {
        throw new Error('createBatch: place is not correct')
      }

      if (!type || !actions[type]) {
        throw new Error('createBatch: type is not correct')
      }

      if (!id) { throw new Error('createBatch: not provided id ') }

      if (!timestamp) { timestamp = new Date() }

      if (place !== 'logs') {
        if (type === 'set' || type === 'update') {
          if (!data) { throw new Error('createBatch: no data') }

          if (type === 'set') {
            const extraData = {}
            if (merge === true) { extraData.merge = true }

            batchArray[batchIndex].set(createDocRef(place, id), data, extraData)
            addBatch()
          } else if (type === 'update') {
            batchArray[batchIndex].update(createDocRef(place, id), data)
            addBatch()
          }
        } else if (type === 'delete') {
          if (!id) { throw new Error('createBatch: no id to delete') }

          batchArray[batchIndex].delete(createDocRef(place, id))
          addBatch()
        }
      }

      if (updateStats) {
        batchArray[batchIndex].update(createDocRef('userStats', getUid()), {
          [(place === 'logs' && logPlace) ? logPlace : place]: increment(+updateStats)
        })
        addBatch()
      }

      if (!noLogs) {
        const newLogData = logData ? logData : {}

        if (logDataInfo) {
          newLogData.info = logDataInfo
        } else if (data && Object.keys(data).length && !logData) {
          for (const [key, value] of Object.entries(data)) {
            if (value !== undefined && value !== null && value._methodName === 'deleteField') {
              data[key] = null
            }
          }
          newLogData.info = data
        }

        const createdLogData = createLogData({
          timestamp,
          place: logPlace ? logPlace : place,
          action: logAction ? logAction : actions[type],
          type: logType,
          id,
          data: newLogData
        })

        batchArray[batchIndex].set(createDocRef('logs', createId('logs')), createdLogData)
        addBatch()
      }
    }

    return batchArray
  } catch (error) {
    throw new Error(error)
  }
}

export function createLogData({ timestamp, place, action, type, id, data }) {
  if (timestamp, place, action.id, data) {
    return {
      timestamp,
      place,
      action,
      type: type ? type : 'sync',
      owner: getUid(),
      id,
      data
    }
  }
}

export const firestoreDeleteField = deleteField()
export const firestoreIncrement = (value) => increment(+value)
export const firebaseArrayRemove = (value) => arrayRemove(value)
export const firebaseArrayUnion = (value) => arrayUnion(value)
export const firebaseTimestamp = Timestamp
export const firebaseTimestampFromDate = (date) => Timestamp.fromDate(new Date(+date))