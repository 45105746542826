import { addFirestoreDoc } from '@/firebase/firestore'
import { getUid } from '@/firebase/auth'
import { tracePerformance } from '@/firebase/performance'
import useNotifications from '@/composables/useNotifications'
import localizeFilter from '@/filters/localize.filter'
import router from '../router'

const errorCodes = {
  'auth/user-not-found': 'UserNotFound',
  'auth/wrong-password': 'WrongPassword',
  'auth/email-already-in-use': 'EmailAlreadyInUse',
  'auth/invalid-email': 'InvalidEmail',
  'auth/argument-error': 'ArgumentError',
  'auth/too-many-requests': 'TooManyRequestsTryAgainLater',
  'auth/popup-closed-by-user': 'Canceled',
  'auth/account-exists-with-different-credential': 'AddSocialFromProfile',
  'permission-denied': 'NoAccess',
  'unavailable': 'NoInternetConnection'
}

const noSaveErrors = [
  'unavailable',
  'auth/wrong-password',
  'auth/user-not-found',
  'auth/email-already-in-use',
  'auth/invalid-email',
  'auth/invalid-continue-uri',
  'auth/popup-closed-by-user',
  'auth/popup-blocked',
  'auth/account-exists-with-different-credential'
]

export default {
  state: {
    error: null,
    firebaseQuotaReached: false
  },
  mutations: {
    setFirebaseQuotaReached(state, value) {
      state.firebaseQuotaReached = value
    },
    clearInfo(state) {
      state.firebaseQuotaReached = false
    }
  },
  actions: {
    setError({ getters, commit }, error) {
      if (!error) { return }

      if (error.code === 'unavailable' && getters.online === false) { return }

      if (error.code === 'resource-exhausted') {
        commit('setFirebaseQuotaReached', true)
        router.push({ name: 'Maintenance' }).catch(() => { })
        return
      }

      const { toastify } = useNotifications()

      if (error.code) {
        if (errorCodes[error.code]) {
          toastify.error(localizeFilter(errorCodes[error.code]))
        } else {
          toastify.error(localizeFilter('Error'))
        }
      } else {
        toastify.error(error)
      }
    },
    async saveErrorInfo({ dispatch, getters }, { error, location, toastId, params }) {
      dispatch('setError', error)

      console.log('[', location, ']: ', error)

      if (toastId) {
        const { toastify } = useNotifications()
        toastify.remove(toastId)
      }

      if (error.code && noSaveErrors.includes(error.code)) { return }

      if (!getters.online) { return }

      const t = tracePerformance('saveErrorInfo')
      t.start()

      try {
        if (params) {
          for (const param of Object.keys(params)) {
            if (params[param] === undefined) { params[param] = 'undefined' }
          }
        }

        await addFirestoreDoc('errors', {
          timestamp: new Date(),
          error: error + '',
          location: location,
          owner: getUid(),
          params,
          active: true
        })
      } catch (e) {
        console.log(`[saveErrorInfo]: ${e}`)
      } finally {
        t.stop()
      }
    }
  },
  getters: {
    firebaseQuotaReached: s => s.firebaseQuotaReached
  }
}
