export default {
  'green-to-blue': 'green-to-blue-page-gradient',
  'blue-to-green': 'blue-to-green-page-gradient',
  'green-to-gray': 'green-to-gray-page-gradient',
  'blue-to-gray': 'blue-to-gray-page-gradient',
  'green-to-yellow': 'green-to-yellow-page-gradient',
  'blue-to-red': 'blue-to-red-page-gradient',
  'indigo-to-green': 'indigo-to-green-page-gradient',
  'indigo-to-red': 'indigo-to-red-page-gradient',
  'purple-to-red': 'purple-to-red-page-gradient',
  'purple-to-pink': 'purple-to-pink-page-gradient',
  'gray-to-gray': 'gray-to-gray-page-gradient'
}
