import store from '../store'

export default function dateFilter(value, format = 'shortDate') {
  if (!value) { return '' }

  const options = {}

  if (format.includes('shortDate')) {
    options.day = '2-digit'
    options.month = '2-digit'
    options.year = 'numeric'
  }

  const locale = store.getters.locale || 'en-US'
  return new Intl.DateTimeFormat(locale, options).format(new Date(value))
}
