import { addFirestoreDoc, onDocSnapshotHandler } from '@/firebase/firestore'
import { getUid } from '@/firebase/auth'
import { tracePerformance } from '@/firebase/performance'
import { setLocalStorage } from '@/helpers/localstorage'
import useNotifications from '@/composables/useNotifications'
import localizeFilter from '@/filters/localize.filter'
import { prepareDateFields } from '@/utils/prepareDateFields'
import router from '../router'
import packageData from './../../package.json'

export default {
  state: {
    appVersion: packageData.version,
    online: true,
    scrollAllowed: true,
    clickedButton: null,
    menuIsOpen: false,
    processing: false,
    maintenance: false,
    maintenanceUnsubscribe: null,
    sessionId: null,
    openedLink: null,
    showLoader: false,
    showResize: false,
    loadingPage: false,
    popUps: [],
    closingPopUpId: null,
    loadedAll: {
      accountsActive: false,
      accountsArchived: false,
      banks: false,
      cardsActive: false,
      cardsArchived: false,
      moneyBoxes: false,
      news: false,
      tagsActive: false,
      tagsArchived: false,
      tagsCollections: false,
      transactions: false,
      userStats: false
    }
  },
  mutations: {
    setOnline(state, status) {
      state.online = status
    },
    setScrollAllowed(state, allowed) {
      state.scrollAllowed = allowed
    },
    setClickedButton(state, value) {
      state.clickedButton = value
      setTimeout(() => {
        state.clickedButton = null
      }, 0)
    },
    setMenuIsOpen(state, value) {
      state.menuIsOpen = value
    },
    setProcessing(state, processing) {
      state.processing = processing
    },
    setMaintenance(state, data) {
      state.maintenance = data
    },
    setMaintenanceUnsubscribe(state, value) {
      state.maintenanceUnsubscribe = value
    },
    setSessionId(state, sessionId) {
      state.sessionId = sessionId
    },
    setOpenedLink(state, value) {
      state.openedLink = value
    },
    setShowLoader(state, val) {
      state.showLoader = val
    },
    setShowResize(state, value) {
      state.showResize = value
    },
    setLoadingPage(state, value) {
      state.loadingPage = value
    },
    setLoadedAll(state, { field, value }) {
      if (field) {
        state.loadedAll[field] = value
      }
    },
    setDataToPopUp(state, { callerId, data = null, fieldName }) {
      if (!callerId) { return }
      const idx = state.popUps.findIndex(element => element.id === callerId)

      if (idx >= 0 && state.popUps[idx]) {
        if (fieldName && !state.popUps[idx].savedData) { return }

        if (fieldName) {
          if (fieldName === 'date' && ['AddTransaction'].includes(state.popUps[idx].name)) {
            for (let i = 0; i < state.popUps[idx].savedData.length; i++) {
              state.popUps[idx].savedData[i][fieldName] = new Date(data)
            }
          } else if (['date', 'travelStartDate', 'travelEndDate'].includes(fieldName)) {
            state.popUps[idx].savedData[fieldName] = new Date(data)
          } else if (fieldName === 'tags' && data) {
            if (state.popUps[idx].savedData.tags && !state.popUps[idx].savedData.tags.includes(data)) {
              state.popUps[idx].savedData.tags.push(data)
            }
          } else {
            state.popUps[idx].savedData[fieldName] = data
          }
        } else {
          state.popUps[idx].savedData = data
        }
      }
    },
    showPopUp(state, { name, incomingData, execute, closeAll }) {
      if (!name) { return }

      const newPopUp = { id: crypto.randomUUID(), name }

      if (state.popUps.length && state.popUps[0].id) {
        newPopUp.callerId = state.popUps[0].id
        state.closingPopUpId = newPopUp.callerId
      }

      if (incomingData) { newPopUp.incomingData = incomingData }
      if (execute) { newPopUp.execute = execute }

      const oldPopUps = [...state.popUps]

      setTimeout(() => {
        state.popUps = [newPopUp]
        if (!closeAll) { state.popUps = [...state.popUps, ...oldPopUps] }
      }, newPopUp.callerId ? 300 : 0)
    },
    closePopUp(state, { id, name, all }) {
      if (!state.popUps.length) { return }

      if (all) {
        state.popUps = [state.popUps[0]]
        id = state.popUps[0].id
        state.popUps[0].callerId = null
      }

      let idx = 0

      if ((id || name) && !all) {
        idx = state.popUps.findIndex(element => {
          if (id) {
            return element.id === id
          } else if (name) {
            return element.name === name
          }
        })
      }

      if (idx < 0 || !state.popUps[idx]) { return }

      state.closingPopUpId = state.popUps[idx].id

      setTimeout(() => {
        state.popUps.splice(idx, 1)
        state.closingPopUpId = null
      }, 300)
    },
    clearInfo(state) {
      state.scrollAllowed = true
      state.clickedButton = null
      state.menuIsOpen = false
      state.processing = false
      state.maintenance = false
      state.maintenanceUnsubscribe = null
      state.sessionId = null
      state.showLoader = false
      state.showResize = false
      state.loadingPage = false
      state.popUps = []
      state.closingPopUpId = null
      state.loadedAll = {
        accountsActive: false,
        accountsArchived: false,
        banks: false,
        cardsActive: false,
        cardsArchived: false,
        moneyBoxes: false,
        news: false,
        tagsActive: false,
        tagsArchived: false,
        tagsCollections: false,
        transactions: false,
        userStats: false
      }
    }
  },
  actions: {
    async setShowPopUp({ commit, dispatch }, name) {
      if (!name) { return }
      if (!['Feedback', 'EditUserPic'].includes(name)) { return }
      await dispatch('setCloseAllMenus')
      commit('showPopUp', { name })
    },
    showToast(context, { type, text }) {
      if (!type || !text) { return }

      const { toastify } = useNotifications()

      if (type === 'info') {
        toastify.info(text)
      } else if (type === 'warning') {
        toastify.warning(text)
      } else if (type === 'error') {
        toastify.error(text)
      }
    },
    setMenuIsOpenAction({ commit }, value) {
      commit('setMenuIsOpen', value)
      commit('setScrollAllowed', !value)
    },
    setCloseAllMenus({ commit, dispatch }) {
      dispatch('setMenuIsOpenAction', false)
      commit('closePopUp', { all: true })
    },
    async createSessionId({ commit }) {
      if (!getUid()) { return }

      const crypto = require('crypto')
      const localStorageName = 'blue-money-box-com-sessionId-' + getUid()
      const sessionId = crypto.randomBytes(20).toString('hex')

      await setLocalStorage({ name: localStorageName, data: null, string: sessionId })
      commit('setSessionId', sessionId)
    },
    async subscribeToMaintenance({ commit, dispatch }) {
      const t = tracePerformance('subscribeToMaintenance')
      t.start()

      try {
        const unsubscribe = onDocSnapshotHandler({
          collectionName: 'settings',
          docName: 'maintenance',
          successHandler: async (maintenance) => {
            if (maintenance.exists()) {
              const data = prepareDateFields(maintenance.data())
              await commit('setMaintenance', data.closed)
            }
            await commit('setMaintenanceUnsubscribe', unsubscribe)
          },
          errorHandler: async (error) => {
            dispatch('setError', error)
            dispatch('logoutAndGoToLogin', { from: 'maintenance-subscription-error-handler' })
          }
        })
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'subscribeToMaintenance', params: {} })
        return false
      } finally {
        t.stop()
      }
    },
    async unsubscribeMaintenance({ dispatch, getters }) {
      const t = tracePerformance('unsubscribeMaintenance')
      t.start()

      try {
        const maintenanceUnsubscribe = getters.maintenanceUnsubscribe
        if (maintenanceUnsubscribe) {
          await maintenanceUnsubscribe()
        }
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'unsubscribeMaintenance', params: {} })
        return false
      } finally {
        t.stop()
      }
    },
    async unsubscribeAll({ dispatch }) {
      const t = tracePerformance('unsubscribeAll')
      t.start()

      try {
        await dispatch('unsubscribeMaintenance')
        await dispatch('unsubscribeNews')
        await dispatch('unsubscribeLogs')
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'unsubscribeAll', params: {} })
        return false
      } finally {
        t.stop()
      }
    },
    async createFeedback({ commit, dispatch, getters }, text) {
      if (!getters.online) { return }

      if (!text) {
        dispatch('setError', localizeFilter('Error'))
        return false
      }

      const t = tracePerformance('createFeedback')
      t.start()

      const { toastify } = useNotifications()
      let toastId

      try {
        const data = {
          owner: getUid(),
          text: text.trim(),
          timestamp: new Date(),
          active: true
        }

        commit('setProcessing', true)
        toastId = toastify.warning(localizeFilter('Saving') + '...', { timeout: null })

        await addFirestoreDoc('feedback', data)

        toastify.replace(toastId, localizeFilter('FeedbackSaved'), 'success')
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'createFeedback', params: { text }, toastId })
        return false
      } finally {
        commit('setProcessing', false)
        t.stop()
      }
    },
    async goHomeAndShowLoader({ commit }) {
      await commit('setShowLoader', true)
      router.push({ name: 'Home' }).catch(() => { })
    }
  },
  getters: {
    appVersion: s => s.appVersion,
    online: s => s.online,
    scrollAllowed: s => s.scrollAllowed,
    clickedButton: s => s.clickedButton,
    menuIsOpen: s => s.menuIsOpen,
    processing: s => s.processing,
    maintenance: s => s.maintenance,
    maintenanceUnsubscribe: s => s.maintenanceUnsubscribe,
    sessionId: s => s.sessionId,
    legalLink: (s, getters) => {
      const locale = getters.locale
      return `${process.env.VUE_APP_SITE_URL}/legal?lang=${locale}`
    },
    openedLink: s => s.openedLink,
    showLoader: s => s.showLoader,
    showResize: s => s.showResize,
    loadingPage: s => s.loadingPage,
    popUps: s => s.popUps,
    closingPopUpId: s => s.closingPopUpId,
    datepickerMinLimit: () => {
      const year = 1900
      const month = 0
      const day = 1
      return new Date(new Date(year, month, day).getUTCFullYear(), new Date(year, month, day).getUTCMonth(), new Date(year, month, day).getUTCDate())
    },
    datepickerMaxLimit: () => {
      return new Date(new Date().getFullYear() + 15, 11, 31)
    },
    loadedAll: (s) => field => {
      return s.loadedAll[field]
    }
  }
}
