import {
  browserLocalPersistence,
  browserSessionPersistence,
  createUserWithEmailAndPassword,
  deleteUser,
  getAdditionalUserInfo,
  getRedirectResult,
  inMemoryPersistence,
  linkWithPopup,
  linkWithRedirect,
  onAuthStateChanged,
  reauthenticateWithCredential,
  sendEmailVerification,
  sendPasswordResetEmail,
  setPersistence,
  signInWithEmailAndPassword,
  signInWithRedirect,
  signInWithPopup,
  signOut,
  unlink,
  updatePassword,
  EmailAuthProvider,
  FacebookAuthProvider,
  GithubAuthProvider,
  GoogleAuthProvider,
} from 'firebase/auth'

import { auth } from '@/firebase/firebase'
import store from '../store'

export function getUserProviders() {
  const providers = []

  if (auth && auth.currentUser && auth.currentUser.providerData) {
    for (const provider of auth.currentUser.providerData) {
      if (provider.providerId && !providers.includes(provider.providerId)) {
        providers.push(provider.providerId)
      }
    }
  }

  return providers
}

export function getUserProviderData(providerId = null, field = null) {
  if (!providerId) { return }
  if (!auth || !auth.currentUser || !auth.currentUser.providerData || !auth.currentUser.providerData.length) { return }

  const providers = auth.currentUser.providerData.filter(prov => prov.providerId === providerId)

  if (!providers || !providers.length) { return }

  return providers[0][field]
}

export function emailVerified() {
  if (auth && auth.currentUser) {
    return auth.currentUser.emailVerified
  }
}

export function getUserRigestred() {
  if (auth && auth.currentUser && auth.currentUser.metadata && auth.currentUser.metadata.creationTime) {
    return new Date(auth.currentUser.metadata.creationTime)
  }
  return null
}

export function getUid() {
  if (auth && auth.currentUser && auth.currentUser.uid) { return auth.currentUser.uid }
  return null
}

export function getUserEmail() {
  if (auth && auth.currentUser && auth.currentUser.email) { return auth.currentUser.email }
  return null
}

export function setLanguageCode(locale) {
  auth.languageCode = locale
}

export async function createUserWithEmailAndPasswordFunction(email = null, password = null) {
  if (!email || !password) { return }

  try {
    const result = await createUserWithEmailAndPassword(auth, email, password)
    return result
  } catch (error) {
    store.dispatch('saveErrorInfo', { error, location: 'createUserWithEmailAndPasswordFunction', params: { email: email } })
  }
}

export async function setUserPersistance(persistance = null) {
  try {
    if (persistance === 'LOCAL') {
      await setPersistence(auth, browserLocalPersistence).catch(() => { })
    } else if (persistance === 'SESSION') {
      await setPersistence(auth, browserSessionPersistence).catch(() => { })
    } else {
      await setPersistence(auth, inMemoryPersistence).catch(() => { })
    }
  } catch (error) {
    store.dispatch('saveErrorInfo', { error, location: 'setUserPersistance', params: { persistance } })
  }
}

export async function signInWithEmailAndPasswordFunction(email = null, password = null) {
  if (!email || !password) { return }
  try {
    const result = await signInWithEmailAndPassword(auth, email, password)
    return result
  } catch (error) {
    store.dispatch('saveErrorInfo', { error, location: 'signInWithEmailAndPasswordFunction', params: { email: email } })
  }
}

export async function deleteFirebaseUser(user = null) {
  try {
    if (user && user.delete) {
      await user.delete()
    } else if (auth.currentUser) {
      await deleteUser(auth.currentUser)
    }
    return true
  } catch (error) {
    store.dispatch('saveErrorInfo', { error, location: 'deleteFirebaseUser', params: { user: user } })
  }
}

export async function getAdditionalUserInfoFirebase(result) {
  try {
    if (result) {
      const answer = await getAdditionalUserInfo(result)
      return answer
    }
    return true
  } catch (error) {
    store.dispatch('saveErrorInfo', { error, location: 'getAdditionalUserInfoFirebase', params: {} })
  }
}

export async function getRedirectResultFirebase() {
  try {
    return getRedirectResult(auth)
  } catch (error) {
    store.dispatch('saveErrorInfo', { error, location: 'getRedirectResultFirebase', params: {} })
  }
}

export async function linkWithPopupFirebase(provider = null) {
  try {
    return linkWithPopup(auth.currentUser, provider)
  } catch (error) {
    store.dispatch('saveErrorInfo', { error, location: 'linkWithPopupFirebase', params: { provider } })
  }
}

export async function linkWithRedirectFirebase(provider = null) {
  try {
    return linkWithRedirect(auth.currentUser, provider)
  } catch (error) {
    store.dispatch('saveErrorInfo', { error: error, location: 'linkWithRedirectFirebase', params: { provider: provider } })
  }
}

export async function unlinkProvider(providerId = null) {
  try {
    return unlink(auth.currentUser, providerId)
  } catch (error) {
    store.dispatch('saveErrorInfo', { error: error, location: 'unlinkProvider', params: { providerId: providerId } })
  }
}

export async function signInWithRedirectFirebase(provider = null) {
  try {
    return signInWithRedirect(auth, provider)
  } catch (error) {
    store.dispatch('saveErrorInfo', { error, location: 'signInWithRedirect', params: { provider } })
  }
}

export async function signInWithPopupFirebase(provider) {
  try {
    return signInWithPopup(auth, provider)
  } catch (error) {
    store.dispatch('saveErrorInfo', { error, location: 'signInWithPopupFirebase', params: { provider } })
  }
}

export async function reauthenticateWithCredentialFunction(password) {
  try {
    const credential = EmailAuthProvider.credential(
      auth.currentUser.email,
      password
    )

    await reauthenticateWithCredential(auth.currentUser, credential)
    return true
  } catch (error) {
    store.dispatch('saveErrorInfo', { error, location: 'reauthenticateWithCredentialFunction', params: {} })
    return false
  }
}

export async function sendEmailVerificationFunction(data = null) {
  try {
    await sendEmailVerification(auth.currentUser, data)
  } catch (error) {
    store.dispatch('saveErrorInfo', { error, location: 'sendEmailVerificationFunction', params: { data } })
  }
}

export async function sendPasswordResetEmailFunction(email = null, callBackUrl = null) {
  if (!email || !callBackUrl) { return }

  try {
    await sendPasswordResetEmail(auth, email, { url: callBackUrl })
  } catch (error) {
    store.dispatch('saveErrorInfo', { error, location: 'sendPasswordResetEmailFunction', params: { email, callBackUrl } })
  }
}

export async function signOutUser() {
  try {
    await signOut(auth)
  } catch (error) {
    store.dispatch('saveErrorInfo', { error, location: 'signOutUser', params: {} })
  }
}

export async function updatePasswordFirebase(password = null) {
  if (!password) { return }

  try {
    await updatePassword(auth.currentUser, password)
  } catch (error) {
    store.dispatch('saveErrorInfo', { error, location: 'updatePasswordFirebase', params: {} })
  }
}

export function createProvider(providerId) {
  if (!providerId) { return }

  try {
    //
  } catch (error) {
    store.dispatch('saveErrorInfo', { error, location: 'createProvider', params: {} })
  }
}

export function setSocialProvider(providerId = null, locale = null) {
  if (!providerId) { return }

  try {
    let provider

    if (providerId === 'google.com') {
      provider = new GoogleAuthProvider()
      provider.addScope('email')
      provider.addScope('profile')
      provider.setCustomParameters({
        prompt: 'select_account'
      })
      provider.setDefaultLanguage(locale)
    } else if (providerId === 'facebook.com') {
      provider = new FacebookAuthProvider()
      provider.addScope('email')
      provider.addScope('public_profile')
      provider.setDefaultLanguage(locale)
    } else if (providerId === 'github.com') {
      provider = new GithubAuthProvider()
      provider.setDefaultLanguage(locale)
    }

    return provider
  } catch (error) {
    store.dispatch('saveErrorInfo', { error, location: 'setSocialProvider', params: { providerId, locale } })
  }
}

export function onAuthStateChangedFunction(func) {
  if (!func || typeof func !== 'function') { return }
  return onAuthStateChanged(auth, func, error => { console.log(error) })
}