import { createRouter, createWebHistory } from 'vue-router'
import { getUid } from '@/firebase/auth'
import store from '../store'
import { setPageTitle } from '@/helpers/page-meta'

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: { layout: 'public', title: '' },
    component: () => import('@/views/Home.vue')
  },
  {
    path: '/offline',
    name: 'Offline',
    meta: { layout: 'public', title: 'Offline' },
    component: () => import('@/views/Offline.vue')
  },
  {
    path: '/transactions',
    name: 'Transactions',
    meta: { layout: 'private', auth: true, addTransactionAllowed: true, toTop: true, smoothScroll: true, title: 'Transactions' },
    component: () => import('@/views/Transactions.vue'),
  },
  {
    path: '/put-to-accounts',
    name: 'PutToAccounts',
    meta: { layout: 'private', auth: true, toTop: true, smoothScroll: true, title: 'DistributionAmongAccounts' },
    component: () => import('@/views/PutToAccounts.vue')
  },
  {
    path: '/accounts',
    name: 'Accounts',
    meta: { layout: 'private', auth: true, addTransactionAllowed: true, toTop: true, smoothScroll: true, title: 'Accounts' },
    component: () => import('@/views/Accounts.vue')
  },
  {
    path: '/money-boxes',
    name: 'MoneyBoxes',
    meta: { layout: 'private', auth: true, addTransactionAllowed: true, toTop: true, smoothScroll: true, title: 'MoneyBoxes' },
    component: () => import('@/views/MoneyBoxes.vue')
  },
  {
    path: '/banks',
    name: 'Banks',
    meta: { layout: 'private', auth: true, addTransactionAllowed: true, toTop: true, smoothScroll: true, title: 'Banks' },
    component: () => import('@/views/Banks.vue')
  },
  {
    path: '/cards',
    name: 'Cards',
    meta: { layout: 'private', auth: true, addTransactionAllowed: true, toTop: true, smoothScroll: true, title: 'Cards' },
    component: () => import('@/views/Cards.vue')
  },
  {
    path: '/tags',
    name: 'Tags',
    meta: { layout: 'private', auth: true, addTransactionAllowed: true, toTop: true, smoothScroll: true, title: 'Tags' },
    component: () => import('@/views/Tags.vue')
  },
  {
    path: '/tags/statistics',
    name: 'TagStatistics',
    meta: { layout: 'private', auth: true, addTransactionAllowed: true, toTop: true, smoothScroll: true, title: 'TagStats' },
    component: () => import('@/views/TagStatistics.vue')
  },
  {
    path: '/tags/collections',
    name: 'TagsCollections',
    meta: { layout: 'private', auth: true, addTransactionAllowed: true, toTop: true, smoothScroll: true, title: 'TagsCollections' },
    component: () => import('@/views/TagsCollections.vue')
  },
  {
    path: '/settings',
    name: 'Settings',
    meta: { layout: 'private', auth: true, toTop: true, smoothScroll: true, title: 'Settings' },
    component: () => import('@/views/Settings.vue')
  },
  {
    path: '/news',
    name: 'News',
    meta: { layout: 'private', auth: true, toTop: true, smoothScroll: true, title: 'News' },
    component: () => import('@/views/News.vue')
  },
  {
    path: '/login',
    name: 'Login',
    meta: { layout: 'public', type: 'login', toTop: true, smoothScroll: true, title: 'Authorization' },
    component: () => import('@/views/Login.vue')
  },
  {
    path: '/maintenance',
    name: 'Maintenance',
    meta: { layout: 'public', title: 'Maintenance' },
    component: () => import('@/views/Maintenance.vue')
  },
  { path: '/:pathMatch(.*)*', redirect: '/' }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to) {
    const scroll = {}
    if (to.meta.toTop) { scroll.top = 0 }
    if (to.meta.smoothScroll) { scroll.behavior = 'smooth' }
    return scroll
  }
})

router.beforeEach((to, from, next) => {
  setPageTitle(to.meta.title)

  const requireAuth = to.matched.some(record => record.meta.auth)

  if (requireAuth && !getUid()) {
    store.commit('setOpenedLink', to.fullPath)
    next('/login?message=firstlogin')
  } else {
    next()
  }
})

export default router
