import { createBatch, firestoreDeleteField } from '@/firebase/firestore'
import { deleteImageFromStorage } from '@/firebase/storage'
import { tracePerformance } from '@/firebase/performance'
import localizeFilter from '@/filters/localize.filter'
import useNotifications from '@/composables/useNotifications'

export default {
  actions: {
    async deleteReceipt({ commit, dispatch, getters }, transactionId) {
      if (!getters.online) { return }

      if (!transactionId || !getters.transactions[transactionId] || !getters.transactions[transactionId].receiptId) {
        dispatch('setError', localizeFilter('Error'))
        return false
      }

      const { toastify } = useNotifications()

      if (getters.processing) {
        toastify.error(localizeFilter('WaitPreviousTask'), {
          icon: 'hourglass_empty'
        })
        return
      }

      const t = tracePerformance('deleteReceipt')
      t.start()

      const syncTimestamp = new Date()
      let toastId

      try {
        commit('setProcessing', true)
        toastId = toastify.warning(localizeFilter('Deleting') + '...', { timeout: null })

        const batchArray = await createBatch([
          {
            timestamp: syncTimestamp,
            type: 'update',
            place: 'transactions',
            id: transactionId,
            data: {
              receiptId: firestoreDeleteField
            },
            logDataInfo: {
              receiptId: null,
              receiptPic: null
            }
          }
        ])

        await dispatch('subscribeToLogs', syncTimestamp)

        for (const batch of batchArray) { await batch.commit() }

        await dispatch('transactionEdited', [{
          transactionId,
          transactionData: {
            receiptId: null,
            receiptPic: null
          }
        }])

        await deleteImageFromStorage('receipts', transactionId)
        toastify.replace(toastId, localizeFilter('Deleted'), 'success')
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'deleteReceipt', params: { transactionId } })
        return false
      } finally {
        commit('setProcessing', false)
        t.stop()
      }
    }
  }
}